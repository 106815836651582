export enum CustodyOnDamageType {
  Carrier,
  Dealer
}

export const custodyOnDamageOptions = [
    { value: CustodyOnDamageType.Carrier, label: 'Lieferant' },
    { value: CustodyOnDamageType.Dealer, label: 'Händler' }
];

export function getCustodyOnDamageOption(custodyOnDamageType: CustodyOnDamageType) {

    for (const option of custodyOnDamageOptions) {
        if (option.value === custodyOnDamageType) {
            return option;
        }
    }

    return custodyOnDamageOptions[0];
}
