import React, { useState, useCallback, useEffect } from "react";
import {
    Stack,
    DetailsList,
    SelectionMode,
    Selection,
    IObjectWithKey,
    IColumn,
    CommandBar,
    ICommandBarItemProps,
    SearchBox,
} from "@fluentui/react";
import UserDto from "app/user-management/user-dto";
import { ColumnSortInfo } from "app/lists/column-sort-info";
import { useHistory } from "react-router-dom";
import Routes from "app/navigation/routes";
import axios from "axios";
import { buildUserColumns } from "./build-user-columns";

const pageSize: number = 20;

export interface UserAdminListProperties {
    navigateToEditUser: (userId: string) => void;
}

const UsersAdminList = (props: UserAdminListProperties) => {
    const history = useHistory();
    const [users, setUsers] = useState<(UserDto | null)[]>([null]);
    const [userColumns, setUserColumns] = useState<IColumn[]>([]);
    const [sortColumn, setSortColumn] = useState<ColumnSortInfo | null>(null);
    const [searchTerm, setSeachTerm] = useState<string>();
    const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);

    const navigateToNewUser = () => history.push(Routes.userManagementNewUser);

    const loadUsers = useCallback(
        async (index: number, appendToExisting: boolean = true) => {
            if (!appendToExisting) {
                setUsers([]);
            }

            const response = await axios.get<UserDto[]>("/api/users", {
                params: {
                    skip: index,
                    take: pageSize + 1,
                    sortField: sortColumn ? sortColumn.sortField : null,
                    isAscendingSortOrder: sortColumn
                        ? !sortColumn.isSortedDescending
                        : null,
                    searchTerm: searchTerm,
                },
            });

            const loadedUsers: (UserDto | null)[] =
                response.data.length > pageSize
                    ? [...response.data.slice(0, pageSize), null]
                    : response.data;

            const newUsers = appendToExisting
                ? [...users.slice(0, users.length - 1), ...loadedUsers]
                : loadedUsers;

            setUsers(newUsers);
        },
        [users, sortColumn, searchTerm]
    );

    const onRenderMissingItem = (index?: number) => {
        if (index != null) {
            loadUsers(index);
        }
        return <></>;
    };

    const onColumnClick = useCallback((_: any, column: IColumn) => {
        const sortInfo: ColumnSortInfo = {
            sortField: column.fieldName!,
            isSortedDescending: !column.isSortedDescending,
        };
        setSortColumn(sortInfo);
    }, []);

    useEffect(() => {
        const columns = buildUserColumns(onColumnClick, sortColumn);
        setUserColumns(columns);
    }, [sortColumn, onColumnClick]);

    useEffect(
        () => {
            loadUsers(0, false);
        },
        // eslint-disable-next-line
        [sortColumn, searchTerm]
    );

    const selection: Selection = new Selection({
        onSelectionChanged: () =>
            onSelectedUserChanged(selection.getSelection()),
    });

    const onSelectedUserChanged = (selectedObjects: IObjectWithKey[]) => {
        if (selectedObjects && selectedObjects.length) {
            setSelectedUser(selectedObjects[0] as UserDto);
        } else {
            setSelectedUser(null);
        }
    };

    const items: ICommandBarItemProps[] = [
        {
            key: "newUser",
            text: "Neuer Benutzer...",
            iconProps: { iconName: "Add" },
            onClick: navigateToNewUser,
        },
        {
            key: "editUser",
            text: "Benutzer bearbeiten...",
            iconProps: { iconName: "Edit" },
            disabled: selectedUser == null,
            onClick: () => props.navigateToEditUser(selectedUser!.id),
        },
    ];

    const searchBar: ICommandBarItemProps[] = [
        {
            key: "searchBar",
            onRender: () => (
                <SearchBox
                    style={{ width: "200px" }}
                    placeholder="Suche..."
                    value={searchTerm}
                    onSearch={(searchValue) => setSeachTerm(searchValue)}
                />
            ),
        },
    ];

    return (
        <Stack>
            <CommandBar items={items} farItems={searchBar} />
            <DetailsList
                items={users}
                columns={userColumns}
                selectionMode={SelectionMode.single}
                onRenderMissingItem={onRenderMissingItem}
                getKey={(item?: UserDto) => (item ? item.id : "null-item")}
                selection={selection}
            />
        </Stack>
    );
};

export default UsersAdminList;
