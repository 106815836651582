import React from 'react';
import CenteredView from 'app/view-layout/centered-view';
import ExternalLink from 'app/navigation/external-link';

export default function Imprint(): JSX.Element {
    return (
        <CenteredView maxWidth='800px'>
            <h1>Impressum</h1>
            <p>
                One Underwriting Agency GmbH<br />
                Caffamacherreihe 16  |  20355 Hamburg  |  t +49 208 6968-9010  |  f +49 208 6968-9011  |  <ExternalLink href='https://oneunderwriting.de/' text='oneunderwriting.de' /><br />
                Geschäftsführer: Heribert Wolter<br />
                Sitz der Gesellschaft: Hamburg; Amtsgericht Hamburg HRB 103174  |  Gläubiger ID: DE65ZZZ00000058357<br />
                Sitz der Zweigniederlassung: Mülheim an der Ruhr<br />
                Eingetragener Versicherungsvertreter nach § 34d Abs. 1 GewO: D-52IT-NFYYI-01; <ExternalLink href='https://www.vermittlerregister.info/' text='www.vermittlerregister.org' /><br />
                Als Inkassobüro zugelassen<br />
                Unsere Datenschutzerklärung finden Sie unter <ExternalLink href='https://oneunderwriting.de/datenschutz' text='oneunderwriting.de/datenschutz' />
            </p>
        </CenteredView>
    );
}