import React, { useContext } from 'react';
import { Stack } from '@fluentui/react';
import aonLogoBright from './aon-logo-bright.svg';
import aonLogoDark from './aon-logo-dark.svg';
import { brightTheme } from 'app/theming/themes';
import { ThemeContext } from 'app/theming/theme-context';
import SwitchThemeButton from 'app/theming/switch-theme-button';
import { Link } from 'react-router-dom';
import LoggedInUserButton from './logged-in-user-button';
import useUserContext from 'app/user-management/user-context';

export interface HeaderProperties {
    switchTheme: () => void;
    logoutUser: () => void;
}

export default function Header(props: HeaderProperties) {

    const theme = useContext(ThemeContext);
    const user = useUserContext();

    return (
        <Stack
            horizontal
            verticalAlign='center'
            horizontalAlign='space-between'
            style={{
                flexBasis: 84,
                paddingLeft: theme.spacing.l1,
                paddingRight: theme.spacing.l1,
                boxShadow: theme.effects.elevation8,
                backgroundColor: theme.palette.neutralLighter
            }}
        >
            <Stack
                horizontal
                verticalAlign='center'
                tokens={{ childrenGap: theme.spacing.l2 }}
            >
                <Link to="/">
                    <img
                        src={theme === brightTheme ? aonLogoBright : aonLogoDark}
                        alt="AON Schadensanzeige"
                        style={{
                            height: '56px',
                            width: '89px'
                        }}
                    />
                </Link>
                <p
                    style={{
                        fontSize: theme.fonts.large.fontSize,
                        fontWeight: 'bold'
                    }}
                >
                    Schadensanzeige für Stellantis Neuwagen
                </p>
            </Stack>

            <Stack
                horizontal
                tokens={{ childrenGap: theme.spacing.l2 }}
            >
                <SwitchThemeButton switchTheme={props.switchTheme} />
                {
                    user &&
                    <LoggedInUserButton
                        user={user}
                        logoutUser={props.logoutUser}
                    />
                }
            </Stack>
        </Stack>
    );
}
