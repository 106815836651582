import { createTheme } from '@fluentui/react';

export const brightThemeName = "bright";
export const darkThemeName = "dark";

export const brightTheme = createTheme({
    palette: {
        themePrimary: '#0872c9',
        themeLighterAlt: '#f3f8fd',
        themeLighter: '#d1e5f6',
        themeLight: '#aad0ef',
        themeTertiary: '#5ea5df',
        themeSecondary: '#2081d0',
        themeDarkAlt: '#0767b5',
        themeDark: '#065799',
        themeDarker: '#054071',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

export const darkTheme = createTheme({
    isInverted: true,
    palette: {
        themePrimary: '#00a2ff',
        themeLighterAlt: '#00060a',
        themeLighter: '#001a29',
        themeLight: '#00304d',
        themeTertiary: '#006199',
        themeSecondary: '#008ee0',
        themeDarkAlt: '#19abff',
        themeDark: '#3db8ff',
        themeDarker: '#70cbff',
        neutralLighterAlt: '#1c1c1c',
        neutralLighter: '#252525',
        neutralLight: '#343434',
        neutralQuaternaryAlt: '#3d3d3d',
        neutralQuaternary: '#454545',
        neutralTertiaryAlt: '#656565',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#121212',
    }
});
