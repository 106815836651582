import { ICalendarStrings } from '@fluentui/react';

const germanCalendarLocalization: ICalendarStrings = {
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    goToToday: 'Heute',
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    closeButtonAriaLabel: 'Schließen',
    nextMonthAriaLabel: 'Nächster Monat',
    nextYearAriaLabel: 'Nächstes Jahr',
    nextYearRangeAriaLabel: 'Nächste Jahre',
    prevMonthAriaLabel: 'Vorheriger Monat',
    prevYearAriaLabel: 'Vorheriges Jahr',
    prevYearRangeAriaLabel: 'Vorherige Jahre',
    weekNumberFormatString: 'KW {0}'
};
export default germanCalendarLocalization;