import React from 'react';
import ChildrenProperties from './children-properties';
import useThemeContext from '../theming/theme-context';
import { Stack } from '@fluentui/react';

export default function MasterView(props: ChildrenProperties): JSX.Element {

    const theme = useThemeContext();
    const marginThickness = theme.spacing.m;
    return (
        <Stack
            style={{
                flexGrow: 1,
                background: theme.palette.white,
                boxShadow: theme.effects.elevation4,
                margin: marginThickness,
                padding: theme.spacing.l1
            }}
        >
            {props.children}
        </Stack>
    );
}
