import React, { CSSProperties } from 'react';
import { AttachmentType } from '../edit-report/attachment-type';
import { FormatOptionLabelMeta } from 'react-select';
import ReactSelectOption from 'app/forms/select-options/react-select-option';
import { Stack } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';

export function buildAttachmentTypeOption(option: ReactSelectOption<AttachmentType>, labelMeta: FormatOptionLabelMeta<any>): React.ReactNode {
    if (labelMeta.context === 'value') {
        return <span>{option.label}</span>
    }
    
    switch (option.data) {
        case AttachmentType.NoticeOfLiability: return <OptionsText label={option.label} isOptional={false} infoText='Schriftliche Schadenmeldung an den Spediteur/Frachtführer' />
        case AttachmentType.Waybill: return <OptionsText label={option.label} isOptional={false} />
        case AttachmentType.Image: return <OptionsText label={option.label} isOptional={false} />
        case AttachmentType.InternalReceipt: return <OptionsText label={option.label} isOptional={false} infoText='An das eigene Autohaus adressierte Reparaturrechnung (netto). Ein KVA ist KEIN Eigenbeleg und damit ist der Vorgang nicht komplett.' />
        case AttachmentType.Assessement: return <OptionsText label={option.label} isOptional={true} infoText='Sollte der Schaden 2.000 EUR netto übersteigen, ist bei Transport- und Lagerwagenschäden ein DEKRA-Gutachter einzuschalten' />
        case AttachmentType.Correspondence: return <OptionsText label={option.label} isOptional={true} infoText='Achtung! Auch bei Taganlieferungen muss die Schadenmeldung an den Spediteur/Frachtführer schriftlich erfolgen' />
        case AttachmentType.ExternalBill: return <OptionsText label={option.label} isOptional={true} />
        case AttachmentType.Miscellaneous: return <OptionsText label={option.label} isOptional={true} />
    }

    return <OptionsText label={option.label} isOptional={false} />
}

interface OptionsTextTypes {
    label: string;
    isOptional: boolean;
    infoText?: string;
}

function OptionsText(props: OptionsTextTypes): JSX.Element {

    const theme = useThemeContext();

    const optionalLabelStyle: CSSProperties = {
        marginLeft: 4,
        fontSize: 11
    }

    const infoTextStyle: CSSProperties = {
        fontSize: 11,
        color: theme.semanticColors.disabledBodyText
    }

    return (
        <Stack>
            <Stack horizontal verticalAlign='center'>
                <span>{props.label}</span>
                { props.isOptional && <span style={optionalLabelStyle}>(optional)</span> }
            </Stack>
            { props.infoText && <span style={infoTextStyle}>{props.infoText}</span> }
        </Stack>
        
    );
}
