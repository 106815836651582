import React from 'react';
import { PrimaryButton } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';
import { useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import CenteredView from 'app/view-layout/centered-view';

const SentPasswordResetPage = () => {

    const theme = useThemeContext();
    const history = useHistory();

    const navigateToLogin = () => history.push(Routes.login);

    return (
        <CenteredView>
            <h3>Wir haben Ihnen eine Email zum Zurücksetzen des Passworts geschickt.</h3>
            <PrimaryButton
                text="Zurück zum Login"
                style={{ marginTop: theme.spacing.l1 }}
                onClick={navigateToLogin}
            />
        </CenteredView>
    )
}

export default SentPasswordResetPage;
