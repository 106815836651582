import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { EditOrganizationDto } from './infrastructure/edit-organization-dto';
import axios from 'axios';
import { Stack, TextField, PrimaryButton, Button, Spinner } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';
import Routes from 'app/navigation/routes';
import { validateZipCode, validateIBAN } from 'app/forms/validation/validations';
import { OrganizationDto } from './infrastructure/organization-dto';
import { EntityWithChangeVector } from 'app/http/entity-with-change-vector';
import { getRequiredError, getIbanError, getZipCodeError } from 'app/forms/validation/validation-errors';
import CenteredView from 'app/view-layout/centered-view';

export default function EditOrganizationPage(): JSX.Element {

    const history = useHistory();
    const theme = useThemeContext();
    const { editOrgId } = useParams();
    const { handleSubmit, errors, setValue, setError, control } = useForm();
    const [isProcessing, setIsProcessing] = useState(false);
    const [awaitSetupEdit, setAwaitSetupEdit] = useState(false);
    const [editEntity, setEditEntity] = useState<EntityWithChangeVector<OrganizationDto> | null>(null);

    const navigateToOrganizations = () => history.push(Routes.userManagementOrganizations);

    useEffect(
        () => {
            async function setupEditOrganization(targetOrgId: string) {
                const response = await axios.get<EntityWithChangeVector<OrganizationDto>>('/api/organizations/' + encodeURIComponent(targetOrgId));
                setEditEntity(response.data);
            }

            if (editOrgId) {
                setAwaitSetupEdit(true);
                setupEditOrganization(editOrgId);
            }

        },
        [editOrgId]
    );

    useEffect(
        () => {
            if (editEntity) {
                setValue('name', editEntity.entity.name);
                setValue('zipCode', editEntity.entity.zipCode);
                setValue('location', editEntity.entity.location);
                setValue('street', editEntity.entity.street);
                setValue('retailerNumber', editEntity.entity.retailerNumber);
                setValue('internationalBankAccountNumber', editEntity.entity.internationalBankAccountNumber);
                setAwaitSetupEdit(false);
            }
        },
        [editEntity, setValue]
    );

    const onSubmit = async (data: any) => {
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);

        try {
            if (editEntity) {
                const editDto: EditOrganizationDto = {
                    ...data,
                    id: editEntity.entity.id,
                    changeVector: editEntity.changeVector
                }
                await axios.put('/api/organizations/edit', editDto);
            } else {
                await axios.post('/api/organizations/add', data as OrganizationDto);
            }
            navigateToOrganizations();
        } catch (error) {
            setIsProcessing(false);
            const errorMessage: string = error.response.data;
            if (errorMessage.startsWith('There already is an organization')) {
                setError('retailerNumber', 'retailerNumberAlreadyInUse');
            } else {
                console.error('received unknown error message when saving organization', error);
            }
        }
    }

    return (
        <CenteredView>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                    <h3 style={{ marginBottom: theme.spacing.m }}>{editOrgId ? 'Händler bearbeiten' : 'Neue Händler'}</h3>

                    <Controller
                        as={
                            <TextField
                                label="Name *"
                                errorMessage={getRequiredError(errors, 'name', 'Bitte Namen angeben')}
                            />
                        }
                        name="name"
                        control={control}
                        rules={{ required: true }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="Straße *"
                                errorMessage={getRequiredError(errors, 'street', 'Bitte Straße angeben')}
                            />
                        }
                        name="street"
                        control={control}
                        rules={{ required: true }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="Postleitzahl *"
                                errorMessage={getZipCodeError(errors, 'zipCode')}
                            />
                        }
                        name="zipCode"
                        control={control}
                        rules={{ required: true, validate: validateZipCode }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="Ort *"
                                errorMessage={getRequiredError(errors, 'location', 'Bitte Ort angeben')}
                            />
                        }
                        name="location"
                        control={control}
                        rules={{ required: true }}
                    />

                    <Controller
                        as={
                            <TextField
                                label="Händlernummer *"
                                errorMessage={getRetailerNumberError(errors)}
                            />
                        }
                        name="retailerNumber"
                        control={control}
                        rules={{ required: true }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="IBAN"
                                errorMessage={getIbanError(errors, 'internationalBankAccountNumber')}
                            />
                        }
                        name="internationalBankAccountNumber"
                        control={control}
                        rules={{ validate: (value: string) => validateIBAN(value) }}
                    />

                    <Stack
                        horizontal={true}
                        horizontalAlign='end'
                        tokens={{ childrenGap: 8, padding: `${theme.spacing.m} 0 0 0` }}>
                        <Button
                            text='Abbrechen'
                            onClick={navigateToOrganizations}
                        />
                        <PrimaryButton
                            text='Speichern'
                            type='submit'
                            disabled={awaitSetupEdit}
                        />
                    </Stack>

                    {
                        isProcessing &&
                        <Spinner style={{ marginTop: theme.spacing.m }} label="Händler wird gespeichert" />
                    }
                </Stack>
            </form>
        </CenteredView>
    );
}

function getRetailerNumberError(errors: any): string {
    const { retailerNumber } = errors;
    if (!retailerNumber) {
        return '';
    }

    if (retailerNumber.type === 'required') {
        return 'Bitte Händlernummer angeben';
    }
    if (retailerNumber.type === 'retailerNumberAlreadyInUse') {
        return 'Diese Händlernummer wird bereits verwendet.'
    }

    return '';
}