import React from 'react';
import { Stack } from '@fluentui/react';
import ChildrenProperties from 'app/view-layout/children-properties';
import useThemeContext from 'app/theming/theme-context';
import ReportSectionHeader, { ReportSectionHeaderProperties } from './report-section-header';

export interface ReportSectionProperties extends ChildrenProperties, ReportSectionHeaderProperties {
    marginBottom?: React.ReactText;
}

export default function ReportSection(props: ReportSectionProperties): JSX.Element {

    const theme = useThemeContext();

    return (
        <Stack
            style={{
                marginBottom: props.marginBottom || theme.spacing.l1
            }}
            tokens={{ childrenGap: theme.spacing.s1 }}
        >
            <ReportSectionHeader headerText={props.headerText} />
            {props.children}
        </Stack>
    );
}