import React from 'react';
import CenteredView from 'app/view-layout/centered-view';
import { Link } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';

export default function Contact(): JSX.Element {

    const theme = useThemeContext();

    return (
        <CenteredView maxWidth='480px'>
            <h1>Kontakt</h1>
            <p style={{ margin: `${theme.spacing.m} 0` }}>Bei Fragen zur Nutzung des Portals wenden Sie sich bitte an:</p>
            <p>
                Bernhard Schöpf<br />
                One Underwriting Agency GmbH<br />
                +49 208 7006 2612<br />
                <Link href='mailto:bernhard.schoepf@oneunderwriting.de'>bernhard.schoepf@oneunderwriting.de</Link>
            </p>
        </CenteredView>
    );
}