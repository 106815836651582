import { ReportDto } from '../report-dto';
import { ReportState } from '../report-state';
import toGermanDate from 'app/forms/calendar/german-date';

export class ReportEntry {

    constructor(
        readonly id: string,
        readonly damageId: string,
        readonly formattedCreatedAt: string,
        readonly formattedDeliveryDate: string,
        readonly createdAtUtc: string,
        readonly deliveryDate: string,
        readonly vin: string,
        readonly state: ReportState,
        readonly stateText: string
    ) { }

    static FromDto(dto: ReportDto): ReportEntry {
        if (!dto) {
            return dto;
        }
        
        return new ReportEntry(
            dto.id,
            dto.damageId,
            toGermanDate(new Date(dto.createdAtUtc)),
            toGermanDate(new Date(dto.deliveryDate)),
            dto.createdAtUtc,
            dto.deliveryDate,
            dto.vin,
            dto.state,
            dto.state === ReportState.Created ? "In Bearbeitung" : dto.state === ReportState.Submitted ? "Schadensanzeige erstellt" : "Meldung an Versicherer erfolgt"
        );
    }
}