import React, { useState } from 'react';
import CenteredView from 'app/view-layout/centered-view';
import { Stack, ComboBox, IComboBoxOption } from '@fluentui/react';
import ThemedSelect from './themed-select';
import useThemeContext from 'app/theming/theme-context';
import ChildrenProperties from 'app/view-layout/children-properties';
import ThemedAsyncSelect from './themed-async-select';
import { OptionsType } from 'react-select';

interface SectionProps extends ChildrenProperties {
    header: string;
}

function Section(props: SectionProps): JSX.Element {

    const theme = useThemeContext();

    return (
        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
            <h2>{props.header}</h2>
            {props.children}
        </Stack>
    );
}

const officeUiOptions: IComboBoxOption[] = [
    { key: 'roleAdmin', text: 'AON Admin' },
    { key: 'roleMember', text: 'Melder' },
    { key: 'roleOrganizationAdmin', text: 'Händler Melder' }
];

const reactSelectOptions = [
    { label: 'AON Admin', value: 'roleAdmin' },
    { label: 'Melder', value: 'roleMember' },
    { label: 'Händler Melder', value: 'roleOrganizationAdmin' },
];

const colorOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
    { value: 'blue', label: 'Blue', color: '#0052CC' },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630' },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];

export default function ThemedSelectExample(): JSX.Element {

    const theme = useThemeContext();

    const [selectedValue, setSelectedValue] = useState<string>();

    const setAsJson = (data: any) => {
        setSelectedValue(JSON.stringify(data));
    };

    const filterColors = (inputValue: string) => {
        return colorOptions.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadOptions: (inputValue: string, callback: (options: OptionsType<any>) => void) => void =
        (inputValue, callback) => {
            setTimeout(() => {
                callback(filterColors(inputValue));
            }, 2000);
        };

    return (
        <CenteredView>
            <Stack tokens={{ childrenGap: theme.spacing.l2 }}>

                <Section header='Single Selects'>
                    <ComboBox
                        label='Rolle'
                        id='Fluent-UI-ComboBox'
                        placeholder='Rolle wählen'
                        options={officeUiOptions}
                    />

                    <ThemedSelect
                        label='Rolle'
                        id='React-Select'
                        placeholder='Rolle wählen...'
                        isClearable={true}
                        options={reactSelectOptions}
                        onChange={setAsJson}
                    />

                    <p>{selectedValue}</p>
                </Section>

                <Section header='Disabled Selects'>
                    <ComboBox
                        label='Rolle'
                        id='Disabled-Fluent-UI-ComboBox'
                        placeholder='Deaktiviert'
                        disabled={true}
                        options={officeUiOptions}
                    />

                    <ThemedSelect
                        label='Rolle'
                        id='Disabled-Select'
                        placeholder='Deaktiviert'
                        isDisabled={true}
                        options={reactSelectOptions}
                    />
                </Section>

                <Section header='Async Selects'>
                    <ThemedAsyncSelect
                        label='Choose a color'
                        loadingMessage={_ => "We're loading colors..."}
                        loadOptions={loadOptions}
                    />
                </Section>

                <Section header='Select with Error'>
                    <ThemedSelect
                        label='Choose a color'
                        errorMessage='Foo'
                        options={colorOptions}
                    />

                    <ThemedAsyncSelect
                        label='Choose a color'
                        loadingMessage={_ => "We're loading colors..."}
                        loadOptions={loadOptions}
                        errorMessage='OMG something went wrong'
                    />
                </Section>
            </Stack>
        </CenteredView>
    );
}

