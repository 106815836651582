import React, { useState } from 'react';
import { Fabric, ITheme } from '@fluentui/react';
import { BrowserRouter } from 'react-router-dom';
import AppShell from './app-shell/app-shell';
import ThemeSwitcher from './theming/theme-switcher';
import { ThemeContext } from './theming/theme-context';

const themeSwitcher = ThemeSwitcher.initialize();

export default function App() {

    const [currentTheme, setCurrentTheme] = useState<ITheme>(themeSwitcher.currentTheme);
    const switchTheme = () => setCurrentTheme(themeSwitcher.switch());
    return (
        <Fabric>
            <ThemeContext.Provider value={currentTheme}>
                <BrowserRouter>
                    <AppShell switchTheme={switchTheme} />
                </BrowserRouter>
            </ThemeContext.Provider>
        </Fabric>
    );
}
