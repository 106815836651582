import { AttachmentInfo } from '../edit-report/attachment-info';

const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2
};

export function convertToMegabyteString(sizeInBytes: number): string  {

    let sizeInMegabytes = sizeInBytes / (1024 * 1024);
    sizeInMegabytes = Math.max(sizeInMegabytes, 0.01);

    return sizeInMegabytes.toLocaleString('de-DE', options) + ' MB';
}

export default interface ExtendedAttachmentInfo extends AttachmentInfo {
    sizeInMegabyte: string;
}

export function convertToExtendedAttachmentInfo(attachmentInfo: AttachmentInfo): ExtendedAttachmentInfo {
    return {
        ...attachmentInfo,
        sizeInMegabyte: convertToMegabyteString(attachmentInfo.sizeInBytes)
    };
}

export function convertToExtendedAttachmentInfos(attachmentInfos: AttachmentInfo[]): ExtendedAttachmentInfo[] {
    return attachmentInfos.map(info => convertToExtendedAttachmentInfo(info));
}
