import React, { useState } from 'react';
import { Stack } from '@fluentui/react';
import Header from './header/header';
import Footer from './footer';
import UserDto from 'app/user-management/user-dto';
import useThemeContext from 'app/theming/theme-context';
import axios from 'axios';
import RoutedContent from 'app/navigation/routed-content';
import { UserContext } from 'app/user-management/user-context';

export interface AppShellProperties {
    switchTheme: () => void;
}

export default function AppShell(props: AppShellProperties): JSX.Element {

    const theme = useThemeContext();

    const [user, setUser] = useState<UserDto | null>(null);

    const loginUser = (user: UserDto, jwtToken: string) => {
        localStorage.setItem('userToken', jwtToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
        setUser(user);
    };

    const logoutUser = () => {
        localStorage.removeItem('userToken');
        delete axios.defaults.headers.common['Authorization'];
        setUser(null);
    };

    return (
        <UserContext.Provider value={user}>
            <Stack
                style={{
                    minHeight: '100vh',
                    backgroundColor: theme.palette.neutralLight,
                    color: theme.palette.black
                }}
            >
                <Header
                    switchTheme={props.switchTheme}
                    logoutUser={logoutUser}
                />

                <RoutedContent loginUser={loginUser} />

                <Footer />
            </Stack>
        </UserContext.Provider>
    );
}