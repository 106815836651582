import React from 'react'
import { Controller } from 'react-hook-form';
import { TextField } from '@fluentui/react';
import { getRequiredError, getIbanError, getEmailError } from 'app/forms/validation/validation-errors';
import { validateIBAN, validateEmail } from 'app/forms/validation/validations';
import ReportSection from './report-section';
import SectionDetailProperties from './section-detail-properties';

export default function ContactSection(props: SectionDetailProperties) {

  return (
    <ReportSection headerText='Kontaktdaten'>
      <Controller
        as={
          <TextField
            label="Name"
            errorMessage={getRequiredError(props.errors, 'contactName', 'Bitte Namen angeben')}
          />
        }
        name="contactName"
        control={props.control}
        rules={{ required: true }}
      />

      <Controller
        as={
          <TextField
            label="Emailadresse"
            errorMessage={getEmailError(props.errors, 'contactEmail')}
          />
        }
        name="contactEmail"
        control={props.control}
        rules={{
          required: true,
          validate: {
            isEmail: validateEmail
          }
        }}
      />

      <Controller
        as={
          <TextField
            label="IBAN"
            errorMessage={getIbanError(props.errors, 'iban')}
          />
        }
        name="iban"
        control={props.control}
        rules={{
          required: true,
          validate: validateIBAN
        }}
      />
    </ReportSection>
  )
}

export const contactSectionInputNames: string[] = [
  'contactName',
  'contactEmail',
  'iban'
];
