export enum CarType {
  NewCar,
  UsedCar
}

export const carTypeOptions = [
    { label: 'Neuwagen', value: CarType.NewCar },
    { label: 'Gebrauchtwagen', value: CarType.UsedCar }
];

export function getCarTypeOption(carType: CarType) {

    for (const option of carTypeOptions) {
        if (option.value === carType) {
            return option;
        }
    }

    throw new Error(`Could not find option for car type "${carType}".`);
}
