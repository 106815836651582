export default class Routes {
    static readonly home = '/';
    static readonly userManagement = '/usermanagement';
    static readonly userManagementOrganizations = Routes.userManagement + '/organizations'
    static readonly userManagementNewOrganization = Routes.userManagementOrganizations + '/new';
    static readonly userManagementEditOrganization = Routes.userManagementOrganizations + '/edit';
    static readonly userManagementUsers = Routes.userManagement + '/users';
    static readonly userManagementNewUser = Routes.userManagementUsers + '/new';
    static readonly userManagementEditUser = Routes.userManagementUsers + '/edit';
    static readonly reports = '/reports';
    static readonly reportSubmittedSuccessfully = Routes.reports + '/submitSuccessful';
    static readonly reportFinishedSuccessfully = Routes.reports + '/finishedSuccessfully';
    static readonly login = '/login';
    static readonly autoLogin = '/auto-login';
    static readonly confirmEmail = '/confirmEmail';
    static readonly emailNotConfirmed = '/emailNotConfirmed';
    static readonly inactiveAccount = '/inactiveAccount';
    static readonly unconfirmedAccount = '/unconfirmedAccount'
    static readonly requestPasswordReset = '/requestPasswordReset';
    static readonly resetPassword = '/resetPassword';
    static readonly signUp = '/signUp';
    static readonly sentPasswordReset = '/sentPasswordReset';
    static readonly accountCreated = '/accountCreated';
    static readonly imprint = '/imprint';
    static readonly contact = '/contact';
    static readonly changePassword = "/user/changePassword"
    static readonly themedSelectExample = '/themedSelectExample';

    static getUserManagementEditOrganizationUrl(orgId: string): string {
        return this.userManagementEditOrganization + '/' + orgId;
    }

    static getUserManagementEditUserUrl(userId: string): string {
        return this.userManagementEditUser + '/' + userId;
    }

    static getEditReportUrl(reportId: string): string {
        return this.reports + '/edit/' + reportId;
    }

    static getFinishReportUrl(reportId: string): string {
        return this.reports + '/finish/' + reportId;
    }
}
