import React from 'react';
import { Stack, Label } from '@fluentui/react';
import AsyncSelect, { Props } from 'react-select/async';
import useThemedSelectStyle from './use-themed-select-style';
import ThemedSelectError from './themed-select-error';

export interface ThemedAsyncSelectProperties extends Props<any> {
    label?: string;
    errorMessage?: string | JSX.Element
}

export default function ThemedAsyncSelect(props: ThemedAsyncSelectProperties): JSX.Element {

    const themedSelectStyles = useThemedSelectStyle();

    return (
        <Stack>
            {
                props.label &&
                <Label
                    htmlFor={props.id}
                    disabled={props.isDisabled}
                >
                    {props.label}
                </Label>
            }
            <AsyncSelect
                {...props}
                styles={themedSelectStyles}
            />
            {
                props.errorMessage &&
                <ThemedSelectError errorMessage={props.errorMessage} />
            }
        </Stack>
    );
}