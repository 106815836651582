import React, { useState, useEffect } from 'react';
import useThemeContext from 'app/theming/theme-context';
import { useParams, useHistory } from 'react-router-dom';
import { EntityWithChangeVector } from 'app/http/entity-with-change-vector';
import { useForm, Controller } from 'react-hook-form';
import Routes from 'app/navigation/routes';
import { UserDetailDto } from './infrastructure/user-detail-dto';
import axios from 'axios';
import { ManipulateUserDto } from './infrastructure/manipulate-user-dto';
import { EditUserDto } from './infrastructure/edit-user-dto';
import { Stack, TextField, PrimaryButton, Spinner, DefaultButton, Checkbox } from '@fluentui/react';
import { PasswordValidationResult, CheckPasswordDto } from '../../sign-up/check-password-dto';
import { validatePassword, validateEmail } from 'app/forms/validation/validations';
import { OrganizationDto } from '../organizations/infrastructure/organization-dto';
import buildReactSelectOption from 'app/forms/select-options/build-react-select-option';
import { FormatOptionLabelMeta } from 'react-select';
import { getRequiredError, getPasswordError, getEmailError } from 'app/forms/validation/validation-errors';
import ReactSelectOption from 'app/forms/select-options/react-select-option';
import { Roles, getRoleString, Role } from '../../role';
import AddNewUserDto from './infrastructure/add-new-user-dto';
import CenteredView from 'app/view-layout/centered-view';
import ThemedSelect from 'app/forms/themed-select/themed-select';
import ThemedAsyncSelect from 'app/forms/themed-select/themed-async-select';
import { ManipulateOrganizationDto } from '../organizations/infrastructure/manipulate-organization-dto';

const defaultValues: any = {
    firstName: '',
    lastName: '',
    email: ''
};

export default function EditUserPage(): JSX.Element {

    const history = useHistory();
    const theme = useThemeContext();
    const { editUserId } = useParams();
    const { handleSubmit, errors, setValue, getValues, watch, clearError, setError, control } = useForm({ defaultValues });
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSettingOrganization, setIsSettingOrganization] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [editEntity, setEditEntity] = useState<EntityWithChangeVector<UserDetailDto> | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [passwordDto, setPasswordDto] = useState<CheckPasswordDto | null>(null);

    const roleWatch = watch('role');

    if (errors.organization && roleWatch && roleWatch.data === Role.AonAdmin) {
        clearError('organization');
    }

    const navigateToUsers = () => history.push(Routes.userManagementUsers);

    const checkPassword = async (password: string): Promise<boolean> => {
        const currValues = getValues();
        const passwordDto = await validatePassword(password, currValues.firstName, currValues.lastName, currValues.email);
        const isValid = passwordDto?.result === PasswordValidationResult.Valid;
        setPasswordDto(isValid ? null : passwordDto);
        return passwordDto != null && passwordDto.result === PasswordValidationResult.Valid;
    }

    const onSubmit = async (data: any) => {
        if (isProcessing) {
            return;
        }

        const formValues = getValues();
        if (roleWatch.data !== Role.AonAdmin && !formValues.organization) {
            setError('organization', 'required');
            return;
        }

        setIsProcessing(true);

        const user: ManipulateUserDto = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email
        }

        const organizationId: string | null = roleWatch.data !== Role.AonAdmin ? data.organization.data.id : null;

        try {
            if (editEntity) {
                const editDto: EditUserDto = {
                    ...user,
                    id: editEntity.entity.id,
                    organizationId: organizationId,
                    role: data.role.data,
                    isActive: isActive,
                    changeVector: editEntity.changeVector,
                    canAdminReceiveEmails: data.canAdminReceiveEmails
                }
                await axios.put('/api/users/edit', editDto);
            } else {
                const addDto: AddNewUserDto = {
                    ...user,
                    password: data.password,
                    isActive: isActive,
                    role: data.role.data,
                    organizationId: organizationId,
                    canAdminReceiveEmails: data.canAdminReceiveEmails
                }
                await axios.post('/api/users/add', addDto);
            }
            navigateToUsers();
        } catch (error) {
            setIsProcessing(false);
            const errorMessage: string = error.response.data;
            if (errorMessage.startsWith('The email')) {
                setError('email', 'used');
            }
        }
    }

    const loadOrganizations = async (searchTerm: string) => {
        const response = await axios.get<OrganizationDto[]>(
            '/api/organizations', {
            params: {
                searchTerm: searchTerm,
                sortField: 'name'
            }
        });
        return response.data.map(organization => buildReactSelectOption(organization, organization.name));
    };

    const deleteUser = async () => {
        await axios.delete('/api/users/delete/' + editEntity!.entity.id);
        history.push(Routes.userManagementUsers);
    };

    const assignOrganization = async () => {

        if (!isInitialized || isSettingOrganization || isProcessing) {
            return;
        }

        if (!editEntity || !editEntity.entity || !editEntity.entity.suggestedOrganization) {
            return;
        }

        setIsSettingOrganization(true);
        const suggestedOrganization = editEntity.entity.suggestedOrganization;
        let organization = suggestedOrganization.existingOrganization;
        if (!organization) {
            const newOrganization: ManipulateOrganizationDto = {
                name: suggestedOrganization.name,
                retailerNumber: suggestedOrganization.retailerNumber,
                street: suggestedOrganization.street,
                zipCode: suggestedOrganization.zipCode,
                location: suggestedOrganization.location,
                internationalBankAccountNumber: suggestedOrganization.internationalBankAccountNumber
            };
            const response = await axios.post<OrganizationDto>('/api/organizations/add', newOrganization);
            organization = response.data;
        }

        const newEditEntity = { ...editEntity };
        newEditEntity.entity.organization = organization;
        newEditEntity.entity.isActive = true;
        newEditEntity.entity.suggestedOrganization = undefined;
        setEditEntity(newEditEntity);

        setIsSettingOrganization(false);
    };

    useEffect(
        () => {
            const setupEditUser = async (targetOrgId: string) => {
                const response = await axios.get<EntityWithChangeVector<UserDetailDto>>('/api/users/' + encodeURIComponent(targetOrgId));
                setEditEntity(response.data);
            }

            setIsInitialized(false);
            if (editUserId) {
                setupEditUser(editUserId);
            } else {
                setIsInitialized(true);
            }
        },
        [editUserId]
    );

    useEffect(
        () => {
            if (!editEntity) {
                return;
            }
            setValue('firstName', editEntity.entity.firstName);
            setValue('lastName', editEntity.entity.lastName);
            setValue('email', editEntity.entity.email);
            setValue('role', buildReactSelectOption(editEntity.entity.role, getRoleString(editEntity.entity.role)));
            setValue('organization', editEntity.entity.organization != null
                ? buildReactSelectOption(editEntity.entity.organization, editEntity.entity.organization.name)
                : undefined
            );
            setValue('canAdminReceiveEmails', editEntity.entity.canAdminReceiveEmails);

            setIsActive(editEntity.entity.isActive);
            setIsInitialized(true);
        },
        [editEntity, setValue]
    );

    return (
        <CenteredView>

            <form
                autoComplete='new-password' // This works for Firefox, but not for Chromium-based browsers
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                    <h3 style={{ marginBottom: theme.spacing.m }}>{editUserId ? 'Benutzer bearbeiten' : 'Neuer Benutzer'}</h3>

                    {
                        editEntity && editEntity.entity.suggestedOrganization &&
                        <div style={{ background: theme.palette.neutralTertiaryAlt, padding: theme.spacing.s1 }}>
                            <div style={{ fontWeight: 'bold' }}>Vorgeschlagener Händler:</div>
                            <div>{editEntity.entity.suggestedOrganization.name}</div>
                            <div style={{ fontSize: '7pt' }}>
                                <div>Händlernummer {editEntity.entity.suggestedOrganization.retailerNumber}</div>
                                <div>{editEntity.entity.suggestedOrganization.street}</div>
                                <div>{editEntity.entity.suggestedOrganization.zipCode} {editEntity.entity.suggestedOrganization.location}</div>
                            </div>
                            <PrimaryButton
                                text='Jetzt zuweisen'
                                iconProps={{ iconName: 'CheckMark' }}
                                onClick={assignOrganization}
                                style={{ marginTop: theme.spacing.s1 }}
                            />
                        </div>
                    }

                    <Controller
                        as={
                            <TextField
                                label="Vorname"
                                errorMessage={getRequiredError(errors, 'firstName', 'Bitte Vorname angeben')}
                            />
                        }
                        name="firstName"
                        control={control}
                        rules={{ required: true }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="Nachname"
                                errorMessage={getRequiredError(errors, 'lastName', 'Bitte Nachname angeben')}
                            />
                        }
                        name="lastName"
                        control={control}
                        rules={{ required: true }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="Email"
                                errorMessage={getEmailError(errors, 'email')}
                            />
                        }
                        name="email"
                        control={control}
                        rules={{
                            required: true,
                            validate: {
                                isEmail: (value: string) => validateEmail(value)
                            }
                        }}
                    />
                    {
                        !editEntity && isInitialized &&
                        <Controller
                            as={
                                <TextField
                                    label="Passwort"
                                    type='password'
                                    autoComplete='new-password' // new-password must also be set on the input element so that Chromium based browsers do not fill the current*
                                    errorMessage={getPasswordError(errors, 'password', passwordDto as CheckPasswordDto)}
                                />
                            }
                            name="password"
                            control={control}
                            rules={{ required: true, validate: async (value: string) => checkPassword(value) }}
                        />
                    }

                    <div>
                        <Controller
                            as={
                                <ThemedSelect
                                    label='Rolle'
                                    options={Roles.map(role => buildReactSelectOption(role, getRoleString(role)))}
                                    placeholder='Rolle auswählen'
                                />
                            }
                            name="role"
                            control={control}
                            rules={{ required: true }}
                        />
                    </div>

                    <div style={{ display: roleWatch && roleWatch.data === Role.AonAdmin ? 'none' : 'block' }}>
                        <Controller
                            as={
                                <ThemedAsyncSelect
                                    label='Händler'
                                    formatOptionLabel={formatOptionLabel}
                                    loadOptions={loadOrganizations}
                                    placeholder='Händler auswählen'
                                    noOptionsMessage={(input) => input.inputValue.length < 1 ? 'Noch 1 Buchstabe' : 'Nichts gefunden'}
                                />
                            }
                            name="organization"
                            control={control}
                        />
                    </div>

                    <div style={{ paddingTop: theme.spacing.s1 }}>
                        <Checkbox
                            label="Aktiv"
                            checked={isActive}
                            onChange={(_: any, checked?: boolean) => setIsActive(checked!)}
                        />
                    </div>

                    <div style={{ display: roleWatch && roleWatch.data === Role.AonAdmin ? 'block' : 'none' }}>
                        <Controller
                            as={<Checkbox label='Erhält Emailbenachrichtigungen' />}
                            name="canAdminReceiveEmails"
                            onChange={([_, checked]: boolean[]) => { return checked }}
                            control={control}
                        />
                    </div>

                    <Stack
                        horizontal
                        horizontalAlign='end'
                        style={{ marginTop: theme.spacing.l2 }}
                        tokens={{ childrenGap: theme.spacing.s1 }}>
                        <DefaultButton
                            text='Abbrechen'
                            onClick={navigateToUsers}
                            disabled={isSettingOrganization}
                        />
                        {
                            editEntity && editEntity.entity.canBeDeleted &&
                            <PrimaryButton
                                text='Löschen'
                                style={{ backgroundColor: theme.palette.red, borderColor: theme.palette.red }}
                                onClick={deleteUser}
                                disabled={!isInitialized || isSettingOrganization}
                            />
                        }
                        <PrimaryButton
                            text='Speichern'
                            type='submit'
                            disabled={!isInitialized || isSettingOrganization}
                        />
                    </Stack>

                    {isProcessing && <Spinner style={{ marginTop: theme.spacing.m }} label="Benutzer wird gespeichert" />}
                </Stack>
            </form>
        </CenteredView>
    );
}

function formatOptionLabel(option: ReactSelectOption<OrganizationDto>, labelMeta: FormatOptionLabelMeta<any>): JSX.Element {
    if (labelMeta.context === 'value') {
        return <span>{option.label}</span>
    }

    return (
        <div>
            <div>{option.label}</div>
            <div style={{ fontSize: '7pt' }}>
                <div>Händlernummer {option.data.retailerNumber}</div>
                <div>{option.data.street}</div>
                <div>
                    <span>{option.data.zipCode} {option.data.location}</span>
                </div>
            </div>
        </div>
    );
}