export enum AttachmentType {
  InternalReceipt,
  NoticeOfLiability,
  Image,
  Waybill,
  Assessement,
  Correspondence,
  DamageReport,
  ExternalBill,
  Miscellaneous
}

export const UploadableAttachmentTypes: AttachmentType[] = [
  AttachmentType.InternalReceipt,
  AttachmentType.NoticeOfLiability,
  AttachmentType.Image,
  AttachmentType.Waybill,
  AttachmentType.Assessement,
  AttachmentType.Correspondence,
  AttachmentType.ExternalBill,
  AttachmentType.Miscellaneous
];

export function getAttachmentTypeName(type: AttachmentType): string {
  switch (type) {
    case AttachmentType.InternalReceipt: return 'Eigenbeleg'
    case AttachmentType.NoticeOfLiability: return 'Haftbarhaltung'
    case AttachmentType.Image: return 'Foto'
    case AttachmentType.Waybill: return 'Frachtbrief'
    case AttachmentType.Assessement: return 'Gutachten'
    case AttachmentType.Correspondence: return 'Schriftwechsel'
    case AttachmentType.DamageReport: return 'Schadensmeldung'
    case AttachmentType.ExternalBill: return 'Fremdrechnung'
    case AttachmentType.Miscellaneous: return 'Sonstiges'
    default: return 'Unbekannt'
  }
}
