import React from 'react';
import { ILinkProps } from '@fluentui/react';
import NavigationLink, { NavigationLinkProperties } from 'app/navigation/navigation-link';
import Routes from 'app/navigation/routes';

export default function BackToReportsLink(properties: ILinkProps): JSX.Element {

    const newProperties: NavigationLinkProperties = {
        ...properties,
        to: Routes.reports,
        text: 'Zurück zu den Schadensanzeigen'
    };

    return <NavigationLink {...newProperties} />;
}