import React, { useState } from 'react';
import { Stack, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import useThemeContext from '../../theming/theme-context';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import CenteredView from 'app/view-layout/centered-view';

export interface AuthResponseProperties {
    type: AuthResponseType
}

export enum AuthResponseType {
    EmailNotConfirmed,
    InactiveAccount,
    NotConfirmedByAonAdmin,
    RegisterConfirmEmail
}

function getResponse(type: AuthResponseType): string[] {
    if (type === AuthResponseType.EmailNotConfirmed) {
        return ['Unbestätigte E-Mail ', 'Bitte aktivieren Sie erst Ihren Account, indem Sie diesen in der von uns gesendeten E-Mail bestätigen.']
    }

    if (type === AuthResponseType.InactiveAccount) {
        return ['Inaktiver Account', 'Ihr Account muss erst von einem AON Admin aktiviert werden. Bitte wenden Sie sich an einen AON Admin.'];
    }

    if (type === AuthResponseType.NotConfirmedByAonAdmin) {
        return ['Unbestätigter Account', 'Ihr Account wird von einem AON Admin freigeschalten.'];
    }

    if (type === AuthResponseType.RegisterConfirmEmail) {
        return ['E-Mail bestätigen', 'Die Registrierung war erfolgreich. Sie können sich anmelden, sobald Sie Ihren Account in der von uns gesendeten E-Mail bestätigt haben.'];
    }

    return ['Fehler bei Anmeldung', 'Wir konnten Sie leider nicht erfolgreich anmelden'];
}

export default function AuthResponsePage(props: AuthResponseProperties): JSX.Element {

    const [initialTitle, initialMessage] = getResponse(props.type);

    const theme = useThemeContext();
    const location = useLocation();
    const locationState = location.state as any;
    const [isCallingService, setIsCallingService] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(initialTitle);
    const [message, setMessage] = useState<string>(initialMessage);
    const [isResendButtonVisible, setIsResendButtonVisible] = useState<boolean>(props.type === AuthResponseType.EmailNotConfirmed && locationState && locationState.params && locationState.params.email);

    const resendConfirmationEmail = async (email: string) => {

        setIsCallingService(true);
        try {
            const response = await axios.post('api/users/resendEmailConfirmation', { email });
            if (response.status === 200) {
                setTitle('Mail versendet');
                setMessage('Wir haben Ihnen eine neue Bestätigungsmail gesendet. Bitte aktivieren Sie über diese Ihren Account.');
                setIsResendButtonVisible(false);
            }
        }
        catch {
            setTitle('Fehler beim Senden');
            setMessage('Leider ist etwas schiefgelaufen - bitte drücken Sie auf "Jetzt nochmal senden".');
        }
        finally {
            setIsCallingService(false);
        }
    }

    return (
        <CenteredView>
            <Stack>
                <h3>{title}</h3>
                <div style={{ marginTop: theme.spacing.m }}>
                    {message}
                </div>

                {
                    isResendButtonVisible &&
                    <Stack
                        tokens={{ childrenGap: theme.spacing.s1 }}
                        style={{ marginTop: theme.spacing.m }}>
                        <h3>Keine E-Mail erhalten?</h3>
                        <PrimaryButton
                            text="Jetzt nochmal senden"
                            onClick={() => resendConfirmationEmail(locationState.params.email)}
                        />
                        {
                            isCallingService &&
                            <Spinner
                                size={SpinnerSize.large}
                            />
                        }
                    </Stack>
                }
            </Stack>
        </CenteredView>
    );
}
