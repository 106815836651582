import React, { useState } from 'react';
import UserDto from 'app/user-management/user-dto';
import useThemeContext from 'app/theming/theme-context';
import { IContextualMenuProps, ContextualMenu, IContextualMenuItem, ContextualMenuItemType, DefaultButton } from '@fluentui/react';
import { useHistory } from 'react-router-dom';

export interface LoggedInUserButtonProperties {
    user: UserDto,
    logoutUser: () => void;
}

function getContextMenu(props: IContextualMenuProps): JSX.Element {
    return <ContextualMenu {...props} />
}

export default function LoggedInUserButton(props: LoggedInUserButtonProperties): JSX.Element {

    const theme = useThemeContext();
    const history = useHistory();
    const [isUserContextMenuOpen, setIsUserContextMenuOpen] = useState(false);

    const createUserMenuItems = (): IContextualMenuItem[] => {

        const navigateToChangePassword = () => {
            history.push('/user/changePassword');
        }

        return [
            {
                key: 'changePassword',
                text: 'Passwort ändern',
                onClick: navigateToChangePassword
            },
            {
                key: 'divider',
                itemType: ContextualMenuItemType.Divider
            },
            {
                key: 'logout',
                text: 'Abmelden',
                onClick: props.logoutUser
            }
        ];
    }

    return (
        <DefaultButton
            text={props.user.firstName + ' ' + props.user.lastName}
            iconProps={{ iconName: 'Contact' }}
            allowDisabledFocus
            style={{
                backgroundColor: theme.palette.neutralLighter
            }}
            menuAs={getContextMenu}
            menuProps={{
                items: createUserMenuItems(),
                hidden: !isUserContextMenuOpen,
                onDismiss: () => setIsUserContextMenuOpen(false),
                onItemClick: () => setIsUserContextMenuOpen(false),
            }}
        />
    );
}