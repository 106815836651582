import { IColumn } from "office-ui-fabric-react";
import { ColumnSortInfo } from '../../lists/column-sort-info';

export function buildDamageDetailsColumns(onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void, sortColumn: ColumnSortInfo | null): IColumn[] {
  return [
    { 
      key: 'areaCode', 
      name: 'Bereich', 
      fieldName: 'areaCode',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'areaCode' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'areaCode' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'damageCode', 
      name: 'Schaden', 
      fieldName: 'damageCode', 
      minWidth: 100, 
      maxWidth: 100,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'damageCode' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'damageCode' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'severityCode', 
      name: 'Schwere', 
      fieldName: 'severityCode',
      minWidth: 100, 
      maxWidth: 100,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'severityCode' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'severityCode' ? sortColumn.isSortedDescending : true
    }
  ];
}