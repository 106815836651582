export interface CheckPasswordDto {
  password: string;
  result: PasswordValidationResult;
  minimumPasswordLength: number;
  maximumPasswordLength: number;
}

export enum PasswordValidationResult {
  Valid,
  PasswordTooShort,
  PasswordTooLong,
  EqualToEmail,
  EqualToName,
  MissingCharacterCategories
}
