export enum ColorCoatingType {
    Metallic,
    Uni
}

export const colorCoatingOptions = [
    { label: 'Metallic', value: ColorCoatingType.Metallic },
    { label: 'Uni', value: ColorCoatingType.Uni }
];

export function getColorCoatingOption(colorCoatingType: ColorCoatingType) {

    for (const option of colorCoatingOptions) {
        if (option.value === colorCoatingType) {
            return option;
        }
    }

    return colorCoatingOptions[0];
}
