import React, { useState } from 'react';
import useThemeContext from 'app/theming/theme-context';
import { Stack, TextField, Spinner, PrimaryButton } from '@fluentui/react';
import { useForm, Controller } from 'react-hook-form';
import { validateEmail } from 'app/forms/validation/validations';
import axios from 'axios';
import { RequestPasswordResetDto } from './request-password-reset-dto';
import { useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import CenteredView from 'app/view-layout/centered-view';

const RequestPasswordResetPage = () => {

  const theme = useThemeContext();
  const [isRequestingNewPassword, setIsRequestingNewPassword] = useState(false);
  const { handleSubmit, errors, setValue, control, setError } = useForm();
  const history = useHistory();

  const onResetSubmit = async (data: any) => {
    if (isRequestingNewPassword) {
      return;
    }
    setIsRequestingNewPassword(true);

    try {
      const request: RequestPasswordResetDto = {
        email: data.email
      }
      await axios.post('/api/users/requestPasswordReset', request);
      history.push(Routes.sentPasswordReset);
    } catch (error) {
      setError('email', 'unknownError');
    } finally {
      setIsRequestingNewPassword(false);
    }
  }

  return (
    <CenteredView>
      <form onSubmit={handleSubmit(onResetSubmit)}>
        <Stack tokens={{ childrenGap: theme.spacing.s1 }} style={{ width: '100%', maxWidth: '280px' }}>
          <h3 style={{ marginBottom: theme.spacing.m }}>Neues Passwort anfordern</h3>
          <Controller
            as={
              <TextField
                label="Email"
                errorMessage={getEmailError(errors)}
              />
            }
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                isEmail: (value: string) => validateEmail(value)
              }
            }}
            setValue={setValue}
          />
          <PrimaryButton
            style={{ marginTop: theme.spacing.l1 }}
            text="Email senden"
            type="submit"
          />

          {
            isRequestingNewPassword &&
            <Spinner style={{ marginTop: theme.spacing.m }} label="Email wird gesendet" />
          }
        </Stack>
      </form>
    </CenteredView>
  )
}

function getEmailError(errors: any): string {
  if (!errors.email) {
    return '';
  } else if (errors.email.type === 'required') {
    return 'Bitte Email angeben';
  } else if (errors.email.type === 'isEmail') {
    return 'Ungültige Email Addresse';
  } else if (errors.email.type === 'notFound') {
    return 'Diese Email ist nicht registriert';
  } else if (errors.email.type === 'unknownError') {
    return 'Fehler bei der Verarbeitung';
  }
  return 'Bitte überprüfen Sie Ihre Eingabe';
}

export default RequestPasswordResetPage;
