import React from 'react'
import { Controller } from 'react-hook-form';
import { TextField, DatePicker, Checkbox, Label, DayOfWeek } from '@fluentui/react';
import { getRequiredError } from 'app/forms/validation/validation-errors';
import useThemeContext from 'app/theming/theme-context';
import { CustodyOnDamageType } from './custody-on-damage-type';
import germanCalendarLocalization from 'app/forms/calendar/german-calendar-localization';
import toGermanDate from 'app/forms/calendar/german-date';
import ReportSection from './report-section';
import SectionDetailProperties from './section-detail-properties';
import ThemedSelect from 'app/forms/themed-select/themed-select';

export interface VehicleDamageSectionProperties extends SectionDetailProperties {
    setValue: (name: string, value: any, shouldValidate: boolean) => any;
    getValues: () => any;
    watch: (name: string) => any;
}

export default function VehicleDamageSection(props: VehicleDamageSectionProperties) {

    const theme = useThemeContext();
    const formValues = props.getValues();
    const isReportedToPoliceWatch = props.watch('isReportedToPolice');

    return (
        <ReportSection headerText='Schadenshintergrund'>
            <div>
                <Label>In wessen Gewahrsam befand sich das Fahrzeug</Label>
                <Controller
                    as={
                        <ThemedSelect
                            options={[
                                { label: 'Lieferant', value: CustodyOnDamageType.Carrier },
                                { label: 'Händler', value: CustodyOnDamageType.Dealer }
                            ]}
                            placeholder=''
                        />
                    }
                    name="custodyOnDamageType"
                    control={props.control}
                    rules={{ required: true }}
                />
            </div>

            <div>
                <DatePicker
                    label="Ausgeliefert am"
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={germanCalendarLocalization}
                    formatDate={(date?: Date) => toGermanDate(date)}
                    onSelectDate={(date) => props.setValue('deliveryDate', date, true)}
                    value={formValues.deliveryDate}
                />
                {
                    props.errors && props.errors.deliveryDate &&
                    <p className='ms-TextField-errorMessage'><span className='error-message'>{getRequiredError(props.errors, 'deliveryDate', 'Bitte Lieferdatum angeben')}</span></p>
                }
            </div>

            <div style={{ paddingTop: theme.spacing.s1, paddingBottom: theme.spacing.s1 }}>
                <Controller
                    as={<Checkbox label='Nachtlieferung' />}
                    name="isNonWorkingHoursDelivery"
                    onChange={([_, checked]: boolean[]) => { return checked }}
                    control={props.control}
                />
            </div>

            <div style={{ paddingTop: theme.spacing.s1, paddingBottom: theme.spacing.s1 }}>
                <Controller
                    as={<Checkbox label='Schaden der Polizei gemeldet' />}
                    name="isReportedToPolice"
                    onChange={([_, checked]: boolean[]) => { return checked }}
                    control={props.control}
                />
            </div>

            {
                isReportedToPoliceWatch &&
                <>
                    <Controller
                        as={
                            <TextField
                                label="Polizei Dienststelle"
                                errorMessage={getRequiredError(props.errors, 'policeDepartment', 'Bitte Dienststelle angeben')}
                            />
                        }
                        name="policeDepartment"
                        control={props.control}
                        rules={{ required: true }}
                    />
                    <Controller
                        as={
                            <TextField
                                label="Tagebuch Nr"
                                errorMessage={getRequiredError(props.errors, 'policeRefNumber', 'Bitte Tagebuch Nr angeben')}
                            />
                        }
                        name="policeRefNumber"
                        rules={{ required: true }}
                        control={props.control}
                    />
                </>
            }
        </ReportSection>
    )
}

export const vehicleDamageSectionInputNames: string[] = [
    'deliveryDate',
    'nonWorkingHoursDelivery',
    'isReportedToPolice',
    'policeDepartment',
    'policeRefNumber'
]
