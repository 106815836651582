import { IColumn } from "office-ui-fabric-react";
import { ColumnSortInfo } from "../../lists/column-sort-info";

export function buildReportColumns(onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void, sortColumn: ColumnSortInfo | null): IColumn[] {
  return [
    { 
      key: 'damageId', 
      name: 'Schadensnummer', 
      fieldName: 'damageId',
      minWidth: 140, 
      maxWidth: 140,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'damageId' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'damageId' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'state', 
      name: 'Status', 
      fieldName: 'stateText', 
      minWidth: 240, 
      maxWidth: 240,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'state' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'state' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'vin', 
      name: 'FIN', 
      fieldName: 'vin',
      minWidth: 200, 
      maxWidth: 200,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'vin' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'vin' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'createdAtUtc', 
      name: 'Erstellt am', 
      fieldName: 'formattedCreatedAt',
      minWidth: 200, 
      maxWidth: 200,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'createdAtUtc' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'createdAtUtc' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'deliveryDate', 
      name: 'Lieferdatum', 
      fieldName: 'formattedDeliveryDate', 
      data: 'string',
      minWidth: 200, 
      maxWidth: 200,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'deliveryDate' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'deliveryDate' ? sortColumn.isSortedDescending : true
    }
  ];
}