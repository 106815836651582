import React, { useState, useEffect, useMemo } from "react";
import {
    Stack,
    TextField,
    Icon,
    Label,
    DefaultButton,
    PrimaryButton,
    Spinner,
    Dialog,
    IconButton,
    TooltipHost,
    TooltipDelay,
} from "@fluentui/react";
import useThemeContext from "app/theming/theme-context";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { getAmountOfDamageError } from "app/forms/validation/validation-errors";
import AttachmentList from "./attachment-list";
import { ReportDetailDto } from "../edit-report/report-detail-dto";
import { EntityWithChangeVector } from "app/http/entity-with-change-vector";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Routes from "app/navigation/routes";
import { FinishReportDto } from "./finish-report-dto";
import {
    AttachmentType,
    getAttachmentTypeName,
    UploadableAttachmentTypes,
} from "../edit-report/attachment-type";
import { AttachmentInfo } from "../edit-report/attachment-info";
import { buildAttachmentTypeOption } from "./build-attachment-type-option";
import buildReactSelectOption from "app/forms/select-options/build-react-select-option";
import EditAttachment from "./edit-attachment";
import { ReportState } from "../report-state";
import CenteredView from "app/view-layout/centered-view";
import useThemedSelectStyle from "app/forms/themed-select/use-themed-select-style";
import Select from "react-select";
import ExtendedAttachmentInfo, {
    convertToExtendedAttachmentInfos,
} from "./extended-attachment-info";

export default function FinishReportPage(): JSX.Element {
    const theme = useThemeContext();
    const selectStyles = useThemedSelectStyle();
    const history = useHistory();
    const { reportId } = useParams();
    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: (files) => uploadAttachments(files),
        accept: "image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const { errors, control, handleSubmit, setError } = useForm({
        mode: "onBlur",
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [showSizeLimitError, setShowSizeLimitError] = useState(false);
    const [selectedAttachmentType, setSelectedAttachmentType] = useState(
        buildReactSelectOption(
            AttachmentType.Image,
            getAttachmentTypeName(AttachmentType.Image)
        )
    );
    const [attachmentInfos, setAttachmentInfos] = useState<
        ExtendedAttachmentInfo[] | null
    >(null);
    const [editAttachment, setEditAttachment] =
        useState<ExtendedAttachmentInfo>();

    const navigateToReports = () => history.push(Routes.reports);

    const uploadAttachments = async (files: File[]) => {
        if (!reportId || !selectedAttachmentType) {
            return;
        }

        for (let i = 0; i < files.length; ++i) {
            const formData = new FormData();
            formData.append("attachment", files[i]);

            const response = await axios.post<AttachmentInfo[]>(
                "/api/reports/uploadAttachment",
                formData,
                {
                    params: {
                        reportId: reportId,
                        attachmentType: selectedAttachmentType.data,
                    },
                    validateStatus: () => true,
                }
            );

            if (response.status === 413) {
                setShowSizeLimitError(true);
                return;
            } else if (response.status !== 200) {
                throw new Error(
                    "Upload attachment endpoint response status code does not indicate success"
                );
            }

            const attachmentInfos = convertToExtendedAttachmentInfos(
                response.data
            );
            setAttachmentInfos(attachmentInfos);

            if (showSizeLimitError) {
                setShowSizeLimitError(false);
            }
        }
    };

    const deleteAttachment = async (attachment: AttachmentInfo) => {
        await axios.delete("/api/reports/deleteAttachment", {
            params: {
                reportId: reportId!,
                attachmentName: attachment.name,
            },
        });

        const newAttachments = attachmentInfos!.filter(
            (info) => info.name !== attachment.name
        );
        setAttachmentInfos(newAttachments);
    };

    const submit = async (data: any) => {
        if (isProcessing) {
            return;
        }

        setIsProcessing(true);

        try {
            let hasInternalReceipt = false;
            let hasNoticeOfLiability = false;
            let hasImage = false;
            let hasWaybill = false;
            attachmentInfos!.forEach((attachment) => {
                switch (attachment.attachmentType) {
                    case AttachmentType.InternalReceipt:
                        hasInternalReceipt = true;
                        break;
                    case AttachmentType.NoticeOfLiability:
                        hasNoticeOfLiability = true;
                        break;
                    case AttachmentType.Image:
                        hasImage = true;
                        break;
                    case AttachmentType.Waybill:
                        hasWaybill = true;
                        break;
                }
            });

            if (!hasInternalReceipt) {
                setError("internalReceipt", "missing");
            }
            if (!hasNoticeOfLiability) {
                setError("noticeOfLiability", "missing");
            }
            if (!hasImage) {
                setError("image", "missing");
            }
            if (!hasWaybill) {
                setError("wayBill", "missing");
            }

            if (
                !hasInternalReceipt ||
                !hasNoticeOfLiability ||
                !hasImage ||
                !hasWaybill
            ) {
                return;
            }

            const dto: FinishReportDto = {
                reportId: reportId!,
                comment: data.comment,
                amountOfDamage: parseFloat(
                    data.amountOfDamage.replace(",", ".").replace(" ", "")
                ),
            };
            const response = await axios.put("/api/reports/finish", dto);
            if (response.status !== 200) {
                throw new Error(
                    "Finish endpoint response status code does not indicate success"
                );
            }

            history.push(Routes.reportFinishedSuccessfully);
        } catch (error) {
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        let isCanceled = false;

        async function loadReport() {
            if (!reportId) {
                history.push("/");
                return;
            }

            const response = await axios.get<
                EntityWithChangeVector<ReportDetailDto>
            >("/api/reports/" + reportId);
            if (!isCanceled) {
                if (response.data.entity.state !== ReportState.Submitted) {
                    history.push(Routes.reports);
                    return;
                }
                setAttachmentInfos(
                    convertToExtendedAttachmentInfos(
                        response.data.entity.attachmentInfos
                    )
                );
            }
        }

        loadReport();

        return () => {
            isCanceled = true;
        };
    }, [history, reportId]);

    const attachmentTypeOptions = useMemo(
        () =>
            UploadableAttachmentTypes.map((type) =>
                buildReactSelectOption(type, getAttachmentTypeName(type))
            ),
        []
    );

    return (
        <CenteredView maxWidth="800px">
            <form onSubmit={handleSubmit(submit)}>
                <Stack>
                    <Stack
                        tokens={{ childrenGap: theme.spacing.s1 }}
                        style={{
                            maxWidth: "300px",
                            minWidth: "300px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        <h3 style={{ marginBottom: theme.spacing.m }}>
                            Nachmeldung
                        </h3>

                        <Controller
                            as={
                                <TextField
                                    label="Schadenshöhe in €"
                                    errorMessage={getAmountOfDamageError(
                                        errors
                                    )}
                                />
                            }
                            name="amountOfDamage"
                            control={control}
                            rules={{
                                required: true,
                                min: 1,
                                validate: (value: string) =>
                                    new RegExp(/^\d+([,|.]\d{1,2})?$/).test(
                                        value
                                    ),
                            }}
                        />
                        <Controller
                            as={
                                <TextField
                                    label="Kommentar (optional)"
                                    multiline
                                />
                            }
                            name="comment"
                            control={control}
                        />

                        <div
                            style={{
                                marginTop: theme.spacing.l2,
                                marginBottom: theme.spacing.l2,
                            }}
                        >
                            <Stack style={{ marginBottom: theme.spacing.m }}>
                                <Stack horizontal>
                                    <Label>Anhang hochladen</Label>
                                    <TooltipHost
                                        tooltipProps={{
                                            onRenderContent:
                                                buildAttachmentTypeTooltip,
                                        }}
                                        delay={TooltipDelay.zero}
                                        id="attachmentTypeTooltip"
                                    >
                                        <IconButton
                                            iconProps={{ iconName: "Info" }}
                                        />
                                    </TooltipHost>
                                </Stack>

                                <Select
                                    options={attachmentTypeOptions}
                                    value={selectedAttachmentType}
                                    onChange={(option) =>
                                        setSelectedAttachmentType(option as any)
                                    }
                                    formatOptionLabel={
                                        buildAttachmentTypeOption
                                    }
                                    styles={selectStyles}
                                />
                            </Stack>

                            <section className="dropzone-container">
                                <div
                                    {...getRootProps({
                                        className: "dropzone",
                                        style: {
                                            height: 240,
                                            padding: theme.spacing.s1,
                                        },
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <h3>Drag &amp; Drop oder Klicken</h3>
                                    <Icon
                                        style={{
                                            marginTop: "4px",
                                            fontSize: "24px",
                                        }}
                                        iconName="Upload"
                                    />
                                    <ol style={{ textAlign: "left" }}>
                                        <li>
                                            Wählen Sie oben den gewünschten
                                            Dokumententyp.
                                        </li>
                                        <li>
                                            Bitte achten Sie darauf, dass die
                                            Anhänge insgesamt nicht größer als
                                            20MB werden.
                                        </li>
                                        <li>
                                            Ihre Dokumente müssen eindeutige
                                            Dateinamen besitzen.
                                        </li>
                                    </ol>
                                </div>
                            </section>
                            {showSizeLimitError && (
                                <p className="ms-TextField-errorMessage">
                                    <span className="error-message">
                                        Die Anhanggröße überschreitet das
                                        erlaubte Maximum
                                    </span>
                                </p>
                            )}
                            {errors.internalReceipt &&
                                (errors.internalReceipt as any).type ===
                                    "missing" && (
                                    <p className="ms-TextField-errorMessage">
                                        <span className="error-message">
                                            {getAttachmentTypeName(
                                                AttachmentType.InternalReceipt
                                            )}{" "}
                                            fehlt
                                        </span>
                                    </p>
                                )}
                            {errors.noticeOfLiability &&
                                (errors.noticeOfLiability as any).type ===
                                    "missing" && (
                                    <p className="ms-TextField-errorMessage">
                                        <span className="error-message">
                                            {getAttachmentTypeName(
                                                AttachmentType.NoticeOfLiability
                                            )}{" "}
                                            fehlt
                                        </span>
                                    </p>
                                )}
                            {errors.image &&
                                (errors.image as any).type === "missing" && (
                                    <p className="ms-TextField-errorMessage">
                                        <span className="error-message">
                                            {getAttachmentTypeName(
                                                AttachmentType.Image
                                            )}{" "}
                                            fehlt
                                        </span>
                                    </p>
                                )}
                            {errors.wayBill &&
                                (errors.wayBill as any).type === "missing" && (
                                    <p className="ms-TextField-errorMessage">
                                        <span className="error-message">
                                            {getAttachmentTypeName(
                                                AttachmentType.Waybill
                                            )}{" "}
                                            fehlt
                                        </span>
                                    </p>
                                )}
                        </div>
                    </Stack>

                    <AttachmentList
                        attachments={attachmentInfos || []}
                        onDeleteAttachment={deleteAttachment}
                        onEditAttachment={(attachment) =>
                            setEditAttachment(attachment)
                        }
                    />

                    <Stack
                        horizontal={true}
                        horizontalAlign="end"
                        tokens={{
                            childrenGap: 8,
                            padding: `${theme.spacing.m} 0 0 0`,
                        }}
                    >
                        <DefaultButton
                            text="Schließen (Anhänge bleiben gespeichert)"
                            onClick={navigateToReports}
                        />
                        <PrimaryButton
                            text="Nachmeldung einreichen"
                            type="submit"
                            disabled={isProcessing}
                        />
                    </Stack>

                    {isProcessing && (
                        <Spinner
                            style={{ marginTop: theme.spacing.m }}
                            label="Nachmeldung wird fertiggestellt"
                        />
                    )}
                </Stack>
            </form>
            <Dialog
                hidden={editAttachment == null}
                onDismiss={() => setEditAttachment(undefined)}
            >
                {editAttachment && reportId && (
                    <EditAttachment
                        attachment={editAttachment}
                        reportId={reportId}
                        onClose={() => setEditAttachment(undefined)}
                        onAttachmentChanged={setAttachmentInfos}
                    />
                )}
            </Dialog>
        </CenteredView>
    );
}

function buildAttachmentTypeTooltip() {
    return (
        <div>
            <div>
                <span style={{ fontWeight: "bold" }}>Schritt 1:</span> Bitte
                wählen Sie den Dokumenttyp aus
            </div>
            <div>
                <span style={{ fontWeight: "bold" }}>Schritt 2:</span> Wählen
                Sie die Datei aus, die Sie hochladen möchten bzw. ziehen Sie die
                Datei auf das Drag & Drop Feld
            </div>
            <div style={{ marginTop: "8px" }}>
                <span style={{ fontWeight: "bold" }}>Hinweis:</span> Sie können
                den Dokumenttyp auch nachträglich in der Liste unten über
                "Bearbeiten" ändern
            </div>
        </div>
    );
}
