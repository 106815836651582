import { brightTheme, darkTheme, darkThemeName, brightThemeName } from './themes';
import { loadTheme, ITheme } from '@fluentui/react';

export default class ThemeSwitcher {

    private internalCurrentTheme: ITheme;

    private constructor(currentTheme: ITheme) {
        this.internalCurrentTheme = currentTheme;
    }

    get currentTheme(): ITheme {
        return this.internalCurrentTheme;
    }

    switch(): ITheme {

        let newThemName;
        if (this.internalCurrentTheme === darkTheme) {
            this.internalCurrentTheme = brightTheme;
            newThemName = brightThemeName;
        }
        else {
            this.internalCurrentTheme = darkTheme;
            newThemName = darkThemeName;
        }

        if (localStorage)
            localStorage.setItem('prefereredTheme', newThemName);

        loadTheme(this.internalCurrentTheme);
        return this.internalCurrentTheme;
    }

    static initialize(): ThemeSwitcher {

        let preferedTheme = brightTheme;
        if (localStorage) {

            const preferedThemeName = localStorage.getItem('prefereredTheme');
            if (preferedThemeName === darkThemeName)
                preferedTheme = darkTheme;
        }

        loadTheme(preferedTheme);

        return new ThemeSwitcher(preferedTheme);
    }

}
