import React, { CSSProperties } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';
import OrganizationsAdministration from './organizations/organizations-administration';
import UsersAdministration from './users/users-administration';
import CaseWorkerSettings from './case-worker-settings/case-worker-settings';
import { useHistory, useParams } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import MasterView from 'app/view-layout/master-view';

const UserManagementPage = () => {

    const theme = useThemeContext();
    const history = useHistory();
    const { selectedPivotItemKey } = useParams();

    const pivotItemStyle: CSSProperties = {
        padding: theme.spacing.s1,
        paddingTop: theme.spacing.l2
    };

    const onPivotItemSelected = (item: PivotItem) => history.push(Routes.userManagement + '/' + item.props.itemKey!);

    return (
        <MasterView>
            <Pivot
                onLinkClick={(item?: PivotItem) => onPivotItemSelected(item!)}
                selectedKey={selectedPivotItemKey}
            >
                <PivotItem itemKey={'users'} headerText='Benutzer' itemIcon='People' style={pivotItemStyle}>
                    <UsersAdministration />
                </PivotItem>
                <PivotItem itemKey={'organizations'} headerText='Händler' itemIcon='Car' style={pivotItemStyle}>
                    <OrganizationsAdministration />
                </PivotItem>
                <PivotItem itemKey={'caseWorkerSettings'} headerText='Sachbearbeiter' itemIcon='Processing' style={pivotItemStyle}>
                    <CaseWorkerSettings />
                </PivotItem>
            </Pivot>
        </MasterView>
    );
}

export default UserManagementPage;
