import { IColumn } from "office-ui-fabric-react";
import { ColumnSortInfo } from "app/lists/column-sort-info";
import buildBooleanTemplate from 'app/lists/build-boolean-template';
import buildRoleTemplate from 'app/lists/build-role-template';
import UserDto from "app/user-management/user-dto";

export function buildUserColumns(onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void, sortColumn: ColumnSortInfo | null): IColumn[] {
    return [
        {
            key: 'firstName',
            name: 'Vorname',
            fieldName: 'firstName',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'firstName' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'firstName' ? sortColumn.isSortedDescending : true
        },
        {
            key: 'lastName',
            name: 'Nachname',
            fieldName: 'lastName',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'lastName' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'lastName' ? sortColumn.isSortedDescending : true
        },
        {
            key: 'email',
            name: 'Email',
            fieldName: 'email',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'email' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'email' ? sortColumn.isSortedDescending : true
        },
        {
            key: 'role',
            name: 'Rolle',
            fieldName: 'role',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'role' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'role' ? sortColumn.isSortedDescending : true,
            onRender: (item: UserDto) => buildRoleTemplate(item.role)
        },
        {
            key: 'organizationName',
            name: 'Händler',
            fieldName: 'organizationName',
            minWidth: 160,
            maxWidth: 160,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'organizationName' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'organizationName' ? sortColumn.isSortedDescending : true
        },
        {
            key: 'isActive',
            name: 'Aktiv',
            fieldName: 'isActive',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'isActive' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'isActive' ? sortColumn.isSortedDescending : true,
            onRender: (item: UserDto) => buildBooleanTemplate(item.isActive)
        },
        {
            key: 'isEmailConfirmed',
            name: 'Email bestätigt',
            fieldName: 'isEmailConfirmed',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'isEmailConfirmed' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'isEmailConfirmed' ? sortColumn.isSortedDescending : true,
            onRender: (item: UserDto) => buildBooleanTemplate(item.isEmailConfirmed)
        },
        {
            key: 'canAdminReceiveEmails',
            name: 'Emailbenachrichtigungen',
            fieldName: 'canAdminReceiveEmails',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'canAdminReceiveEmails' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'canAdminReceiveEmails' ? sortColumn.isSortedDescending : true,
            onRender: (item: UserDto) => buildBooleanTemplate(item.canAdminReceiveEmails)
        },
    ];
}