import React from 'react';
import { IColumn, Link } from "@fluentui/react";
import { ColumnSortInfo } from "app/lists/column-sort-info";
import { AttachmentInfo } from "../edit-report/attachment-info";
import { AttachmentType, getAttachmentTypeName } from "../edit-report/attachment-type";
import download from 'app/http/download';
import formatRelativeUrl from 'app/http/format-relative-url';

export function buildAttachmentColumns(onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void, sortColumn: ColumnSortInfo | null): IColumn[] {
    return [
        {
            key: 'attachmentType',
            name: 'Dokumententyp',
            fieldName: 'attachmentType',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'damageId' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'damageId' ? sortColumn.isSortedDescending : true,
            onRender: (item: AttachmentInfo) => bulidAttachmentTypeTemplate(item.attachmentType)
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 240,
            maxWidth: 240,
            isResizable: true,
            onColumnClick: onColumnClick,
            isSorted: sortColumn && sortColumn.sortField === 'damageId' ? true : false,
            isSortedDescending: sortColumn && sortColumn.sortField === 'damageId' ? sortColumn.isSortedDescending : true,
            onRender: buildAttachmentNameTemplate
        },
        {
            key: 'size',
            name: 'Größe',
            fieldName: 'sizeInMegabyte',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true
        }
    ]
}

function buildAttachmentNameTemplate(attachment: AttachmentInfo) {
    return (
        <Link onClick={() => download(formatRelativeUrl(attachment.url), attachment.name, attachment.mimeType)}>
            {attachment.name}
        </Link>
    );
}

function bulidAttachmentTypeTemplate(type: AttachmentType) {
    return (
        <span>{getAttachmentTypeName(type)}</span>
    );
}
