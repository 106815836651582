import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Stack, Spinner, PrimaryButton } from '@fluentui/react';
import { ThemeContext } from 'app/theming/theme-context';
import axios from 'axios';
import { ConfirmEmailDto } from './confirm-email-dto';
import Routes from 'app/navigation/routes';
import CenteredView from 'app/view-layout/centered-view';

export default function ConfirmEmail() {

    const theme = useContext(ThemeContext);
    const [isTokenInvalid, setIsTokenInvalid] = useState(false);
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const navigateToLogin = useCallback(() => history.push(Routes.login), [history]);

    useEffect(() => {
        const confirmEmail = async (token: string) => {
            const confirmDto: ConfirmEmailDto = {
                token: token
            }
            try {
                await axios.put('/api/users/confirmEmail', confirmDto);
                setIsEmailConfirmed(true);
            } catch (error) {
                setIsTokenInvalid(true);
            }
        };

        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (!token) {
            setIsTokenInvalid(true);
            return;
        }

        confirmEmail(token);
    }, [location.search]);

    useEffect(() => {
        if (isEmailConfirmed) {
            const navTimeout = setTimeout(() => {
                navigateToLogin();
            }, 3000);

            return () => {
                clearTimeout(navTimeout);
            }
        }
    }, [isEmailConfirmed, navigateToLogin]);

    return (
        <CenteredView>
            <Stack>
                {
                    !isTokenInvalid && !isEmailConfirmed &&
                    <Spinner label="Email wird bestätigt" />
                }
                {
                    isTokenInvalid && !isEmailConfirmed &&
                    <div>
                        <h3>Dieser Link ist nicht mehr gültig.</h3>
                        <p>Auf der Login-Seite könnne Sie eine neue Bestätigungsemail anfordern.</p>
                        <PrimaryButton
                            style={{ marginTop: theme.spacing.l1 }}
                            text="Zurück zum Login"
                            onClick={navigateToLogin}
                        />
                    </div>
                }
                {
                    isEmailConfirmed &&
                    <div>
                        <h3>Ihre Email wurde bestätigt</h3>
                        <p>Sie werden in Kürze zum Login weitergeleitet</p>
                    </div>
                }
            </Stack>
        </CenteredView>
    );
}
