import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import useThemeContext from 'app/theming/theme-context';
import { Stack, Spinner, PrimaryButton, Dialog, DefaultButton, SpinnerSize, Label } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import VehicleDetailsSection from './vehicle-details-section';
import VehicleDamageSection from './vehicle-damage-section';
import ContactSection from './contact-section';
import { colorCoatingOptions, getColorCoatingOption } from './color-coating-type';
import { getCustodyOnDamageOption, custodyOnDamageOptions } from './custody-on-damage-type';
import AddDamageDetail from './add-damage-detail';
import { DamageDetail } from './damage-detail';
import { EditReportDto } from './edit-report-dto';
import { ReportDetailDto } from './report-detail-dto';
import axios from 'axios';
import { EntityWithChangeVector } from 'app/http/entity-with-change-vector';
import { carTypeOptions, getCarTypeOption } from './car-type';
import { SubmitReportDto } from './submit-report-dto';
import { ReportSubmittedDto } from './report-submitted-dto';
import { ReportState } from '../report-state';
import CenteredView from 'app/view-layout/centered-view';
import DamageDetailsSection from './damage-details-section';
import { hasErrors } from 'app/forms/validation/validations';

const defaultFormValues = {
    carModel: '',
    carType: carTypeOptions[0],
    colorCoating: '',
    colorCoatingType: colorCoatingOptions[0],
    vin: '',
    typeKeyNumber: '',
    wayBillNumber: '',

    custodyOnDamageType: custodyOnDamageOptions[0],
    deliveryDate: new Date(),
    isNonWorkingHoursDelivery: false,
    isReportedToPolice: false,
    policeDepartment: '',
    policeRefNumber: '',

    contactName: '',
    contactEmail: '',
    iban: '',
    damageDetails: new Array<DamageDetail>()
};

export default function EditReportPage() {

    const theme = useThemeContext();
    const history = useHistory();
    const { reportId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [changeVector, setChangeVector] = useState<string>();
    const [isAddDetailHidden, setIsAddDetailHidden] = useState(true);

    const { errors, control, handleSubmit, triggerValidation, getValues, setValue, register, watch, reset, clearError } = useForm({
        mode: 'onBlur',
        defaultValues: defaultFormValues
    });

    const damageDetailsWatch = watch('damageDetails');

    const navigateToReports = () => history.push(Routes.reports);

    const onDetailCreated = (detail: DamageDetail) => {
        const newDetails = [
            ...getValues().damageDetails as DamageDetail[],
            detail
        ];
        setValue('damageDetails', newDetails, true);
        setIsAddDetailHidden(true);
    }

    const onDetailDeleted = (detail: DamageDetail) => {
        const currentDetails = getValues().damageDetails as DamageDetail[];
        const indexToDelete = currentDetails.findIndex(((currentDetail: DamageDetail) => currentDetail.areaCode === detail.areaCode && currentDetail.damageCode === detail.damageCode && currentDetail.severityCode === detail.severityCode));

        const newDetails = currentDetails.filter((_, index) => index !== indexToDelete);
        setValue('damageDetails', newDetails, true);
    }

    const onSaveReportClick = async () => {
        clearError();
        const values = getValues();
        const damageDetails = values.damageDetails as DamageDetail[];
        const isValid =
            (!values.carModel || await triggerValidation('carModel')) &&
            (!values.carType || await triggerValidation('carType')) &&
            (!values.colorCoating || await triggerValidation('colorCoating')) &&
            (!values.colorCoatingType || await triggerValidation('colorCoatingType')) &&
            (!values.vin || await triggerValidation('vin')) &&
            (!values.typeKeyNumber || await triggerValidation('typeKeyNumber')) &&
            (!values.wayBillNumber || await triggerValidation('wayBillNumber')) &&
            (!values.custodyOnDamageType || await triggerValidation('custodyOnDamageType')) &&
            (!values.deliveryDate || await triggerValidation('deliveryDate')) &&
            (!values.isNonWorkingHoursDelivery || await triggerValidation('isNonWorkingHoursDelivery')) &&
            (!values.isReportedToPolice || await triggerValidation('isReportedToPolice')) &&
            (!values.policeDepartment || await triggerValidation('policeDepartment')) &&
            (!values.policeRefNumber || await triggerValidation('policeRefNumber')) &&
            (!values.contactName || await triggerValidation('contactName')) &&
            (!values.contactEmail || await triggerValidation('contactEmail')) &&
            (!values.iban || await triggerValidation('iban')) &&
            (!damageDetails || damageDetails.length < 1 || await triggerValidation('damageDetails'));

        if (isValid) {
            await submit(values, false);
        }
    }

    const submit = async (data: any, shouldSubmit: boolean = false) => {
        if (isProcessing || !changeVector) {
            return;
        }
        setIsProcessing(true);

        try {
            const editDto: EditReportDto = {
                id: reportId!,
                carModel: data.carModel,
                carType: data.carType.value,
                vin: data.vin,
                typeKeyNumber: data.typeKeyNumber,
                colorCoating: data.colorCoating,
                colorCoatingType: data.colorCoatingType?.value,
                deliveryDate: data.deliveryDate,
                isNonWorkingHoursDelivery: data.isNonWorkingHoursDelivery,
                waybillNumber: data.wayBillNumber,
                custodyOnDamageType: data.custodyOnDamageType?.value,
                isReportedToPolice: data.isReportedToPolice,
                policeDepartment: data.policeDepartment,
                policeRefNumber: data.policeRefNumber,
                contactName: data.contactName,
                contactEmail: data.contactEmail,
                hasUserConfirmed: data.hasUserConfirmed,
                internationalBankAccountNumber: data.iban,
                changeVector: changeVector,
                damageDetails: data.damageDetails
            };

            await axios.put('/api/reports/edit', editDto);

            if (shouldSubmit) {
                const submitDto: SubmitReportDto = {
                    reportId: editDto.id
                }

                await axios.put<ReportSubmittedDto>('/api/reports/submit', submitDto);
                history.push(Routes.reportSubmittedSuccessfully);
                return;
            }

            history.push(Routes.reports);
        } catch (error) {
            // TODO: we should actually display an error message here
            console.error('Could not save report', error);
            setIsProcessing(false);
        }
    }

    useEffect(
        () => {
            register('deliveryDate', { required: true });
            register('damageDetails', { validate: (value) => value != null && value.length > 0 })
        },
        [register]
    );

    useEffect(() => {
        async function loadReport() {
            if (!reportId) {
                history.push('/');
                return;
            }

            // TODO: what happens when an error occurs during this call?
            const response = await axios.get<EntityWithChangeVector<ReportDetailDto>>('/api/reports/' + encodeURIComponent(reportId));
            const entity = response.data.entity;

            if (response.data.entity.state !== ReportState.Created) {
                history.push(Routes.reports);
                return;
            }
            reset({
                carModel: getTextfieldValue(entity.carModel),
                carType: getCarTypeOption(entity.carType),
                vin: getTextfieldValue(entity.vin),
                typeKeyNumber: getTextfieldValue(entity.typeKeyNumber),
                colorCoating: getTextfieldValue(entity.colorCoating),
                colorCoatingType: getColorCoatingOption(entity.colorCoatingType),
                deliveryDate: entity.deliveryDate == null ? new Date() : new Date(entity.deliveryDate),
                isNonWorkingHoursDelivery: entity.isNonWorkingHoursDelivery == null ? false : entity.isNonWorkingHoursDelivery,
                wayBillNumber: getTextfieldValue(entity.waybillNumber),
                custodyOnDamageType: getCustodyOnDamageOption(entity.custodyOnDamageType),
                isReportedToPolice: entity.isReportedToPolice == null ? false : entity.isReportedToPolice,
                policeDepartment: getTextfieldValue(entity.policeDepartment),
                policeRefNumber: getTextfieldValue(entity.policeRefNumber),
                contactName: getTextfieldValue(entity.contactName),
                contactEmail: getTextfieldValue(entity.contactEmail),
                iban: getTextfieldValue(entity.internationalBankAccountNumber),
                damageDetails: entity.damageDetails == null ? [] : entity.damageDetails
            });
            setChangeVector(response.data.changeVector);
            setIsLoading(false);
        }

        loadReport();
    }, [reportId, history, reset]);

    return (
        <CenteredView maxWidth='720px'>

            {
                isLoading &&
                <Spinner size={SpinnerSize.large} />
            }
            {
                !isLoading &&
                <form onSubmit={handleSubmit((data: any) => submit(data, true))}>
                    <Stack style={{ width: '100%' }}>
                        <Stack tokens={{ childrenGap: theme.spacing.s1 }} style={{ maxWidth: '360px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: theme.spacing.m }}>
                            <VehicleDetailsSection control={control} errors={errors} />
                            <VehicleDamageSection control={control} errors={errors} getValues={getValues} setValue={setValue} watch={watch} />
                            <ContactSection control={control} errors={errors} />
                        </Stack>

                        <DamageDetailsSection
                            errors={errors}
                            details={damageDetailsWatch as any}
                            onCreateDetailClick={() => setIsAddDetailHidden(false)}
                            onDeleteDetailClick={onDetailDeleted}
                        />

                        <Stack
                            horizontal={true}
                            horizontalAlign='end'
                            tokens={{ childrenGap: 8, padding: `${theme.spacing.m} 0 0 0` }}
                        >
                            <DefaultButton
                                text='Abbrechen'
                                onClick={navigateToReports}
                                disabled={isProcessing}
                            />
                            <PrimaryButton
                                text='Zwischenspeichern'
                                onClick={onSaveReportClick}
                                disabled={isProcessing}
                            />
                            <PrimaryButton
                                text='Anzeige einreichen'
                                type='submit'
                                disabled={isProcessing}
                            />
                        </Stack>
                        {
                            hasErrors(errors) &&
                            <Label
                                style={{
                                    color: theme.semanticColors.errorText,
                                    textAlign: 'right'
                                }}
                            >
                                Es wurden nicht alle Pflichtfelder korrekt ausgefüllt. Bitte überprüfen Sie Ihre Eingaben.
                        </Label>
                        }

                        {
                            isProcessing &&
                            <Spinner style={{ marginTop: theme.spacing.m }} label="Schadensanzeige wird gespeichert" />
                        }
                    </Stack>
                </form>
            }
            <Dialog
                hidden={isAddDetailHidden}
                onDismiss={() => setIsAddDetailHidden(true)}
            >
                <AddDamageDetail
                    onClose={() => setIsAddDetailHidden(true)}
                    onDetailCreated={onDetailCreated}
                />
            </Dialog>
        </CenteredView>
    );
}

function getTextfieldValue(value: string) {
    if (!value) {
        return '';
    }
    return value;
}
