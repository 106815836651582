import React from 'react';
import { Stack, PrimaryButton, DefaultButton } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';
import { Controller, useForm } from 'react-hook-form';
import AreaCodes from './area-codes';
import { damageCodeOptions } from './damage-code';
import { severityCodeOptions } from './severity-code';
import { DamageDetail } from './damage-detail';
import ThemedSelect from 'app/forms/themed-select/themed-select';

interface AddDamageDetailProperties {
    onClose: () => void;
    onDetailCreated: (detail: DamageDetail) => void;
}

export default function AddDamageDetail(props: AddDamageDetailProperties): JSX.Element {

    const theme = useThemeContext();
    const { control, handleSubmit } = useForm();

    const onSubmit = (data: any) => {

        const newDetail: DamageDetail = {
            areaCode: data.areaCode.value,
            damageCode: data.damageCode.value,
            severityCode: data.severityCode.value
        }
        props.onDetailCreated(newDetail);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
                tokens={{ childrenGap: theme.spacing.m, padding: theme.spacing.l1 }}
                verticalAlign='center'
            >
                <h3 style={{ marginBottom: theme.spacing.m }}>Schaden anlegen</h3>

                <div>
                    <Controller
                        as={
                            <ThemedSelect
                                label='Bereich'
                                options={AreaCodes.options}
                                placeholder='Bereich auswählen'
                            />
                        }
                        name="areaCode"
                        control={control}
                        rules={{ required: true }}
                    />
                </div>

                <div>
                    <Controller
                        as={
                            <ThemedSelect
                                label='Schaden'
                                options={damageCodeOptions}
                                placeholder='Schaden auswählen'
                            />
                        }
                        name="damageCode"
                        control={control}
                        rules={{ required: true }}
                    />
                </div>

                <div>
                    <Controller
                        as={
                            <ThemedSelect
                                label='Schwere'
                                options={severityCodeOptions}
                                placeholder='Schwere auswählen'
                            />
                        }
                        name="severityCode"
                        control={control}
                        rules={{ required: true }}
                    />
                </div>

                <Stack
                    horizontal={true}
                    horizontalAlign='end'
                    style={{ paddingTop: theme.spacing.l1 }}
                    tokens={{ childrenGap: theme.spacing.m }}
                >
                    <DefaultButton
                        text='Abbrechen'
                        onClick={props.onClose}
                    />
                    <PrimaryButton
                        text='Erfassen'
                        type='submit'
                    />
                </Stack>
            </Stack>
        </form>
    );
}
