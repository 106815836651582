export enum DamageCode {
    Broken = 2,
    Dent = 4,
    Chip = 5,
    Missing = 8,
    DirtyInterior = 10,
    Puncture = 11,
    Scratch = 12,
    BrokenGlass = 21
}

export const damageCodeOptions = [
    { label: 'Gebrochen', value: DamageCode.Broken },
    { label: 'Delle', value: DamageCode.Dent },
    { label: 'Lackplatzer', value: DamageCode.Chip },
    { label: 'Fehlt', value: DamageCode.Missing },
    { label: 'Verschmutzung im Innenraum', value: DamageCode.DirtyInterior },
    { label: 'Loch', value: DamageCode.Puncture },
    { label: 'Kratzer', value: DamageCode.Scratch },
    { label: 'Glas gebrochen', value: DamageCode.BrokenGlass }
];