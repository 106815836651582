import React, { useContext, useState } from 'react';
import { Stack, TextField, PrimaryButton, Spinner, SpinnerSize, MessageBar, MessageBarType } from '@fluentui/react';
import { ThemeContext } from '../../theming/theme-context';
import { useForm, Controller } from 'react-hook-form';
import { getApplyPasswordError, getPasswordError } from '../../forms/validation/validation-errors';
import ChangePasswordDto from './change-password-dto';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { CheckPasswordDto, PasswordValidationResult } from '../sign-up/check-password-dto';
import { UserContext } from '../user-context';
import UserDto from '../user-dto';
import CenteredView from 'app/view-layout/centered-view';

export default function ChangePasswordPage(): JSX.Element {

    const theme = useContext(ThemeContext);
    const user = useContext(UserContext) as UserDto;
    if (!user) {
        throw new Error('User should not be able to navigate to ChangePasswordPage without logging in.');
    }
    const history = useHistory();
    const { handleSubmit, control, errors, setError } = useForm();
    const [isCallingService, setIsCallingService] = useState<boolean>(false);
    const [checkPasswordDto, setCheckPasswordDto] = useState<CheckPasswordDto | null>(null);
    const [successMessage, setSuccessMessage] = useState<string>('');

    const checkPassword = async (password: string): Promise<boolean> => {
        const response = await axios.get<CheckPasswordDto>('/api/users/checkPassword', {
            params: {
                password: password,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName
            }
        });
        const isValid = response.data.result === PasswordValidationResult.Valid;
        setCheckPasswordDto(isValid ? null : response.data);
        return isValid;
    }

    const submit = async (data: any): Promise<void> => {
        const dto: ChangePasswordDto = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword
        };

        setIsCallingService(true);

        try {
            await axios.put('/api/users/changePassword', dto);
            setSuccessMessage('Ihr Passwort wurde erfolgreich geändert. Sie werden in wenigen Momenten weitergeleitet...');
            setTimeout(() => history.push('/'), 5000);
        }
        catch (error) {
            const errorMessage: string = error.response ? error.response.data : '';
            if (errorMessage === 'The current password is invalid.') {
                setError('currentPassword', 'invalid');
            }
        }
        finally {
            setIsCallingService(false);
        }
    };

    return (
        <CenteredView>
            <form onSubmit={handleSubmit(submit)}>
                <Stack tokens={{ childrenGap: theme.spacing.s1 }}>

                    <h3 style={{ marginBottom: theme.spacing.m }}>Passwort ändern</h3>

                    <Controller
                        as={
                            <TextField
                                label="Aktuelles Passwort "
                                type='password'
                                required
                                errorMessage={getApplyPasswordError(errors, 'currentPassword')}
                            />
                        }
                        name='currentPassword'
                        rules={{
                            required: true
                        }}
                        control={control}
                    />

                    <Controller
                        as={
                            <TextField
                                label='Neues Passwort '
                                type='password'
                                required
                                errorMessage={getPasswordError(errors, 'newPassword', checkPasswordDto as CheckPasswordDto)}
                            />
                        }
                        name='newPassword'
                        rules={{ required: true, validate: async (value: string) => checkPassword(value) }}
                        control={control}
                    />

                    {
                        !successMessage &&
                        <PrimaryButton
                            style={{ marginTop: theme.spacing.l1 }}
                            text='Passwort ändern'
                            type='submit'
                        />
                    }
                    {
                        isCallingService &&
                        <Spinner size={SpinnerSize.large} />
                    }
                    {
                        successMessage &&
                        <MessageBar messageBarType={MessageBarType.success}>
                            {successMessage}
                        </MessageBar>
                    }
                </Stack>
            </form>
        </CenteredView>
    );
}