import React, { useEffect, useState, CSSProperties } from 'react';
import UnassignedUsers from './unassigned-users';
import UsersAdminList from './users-admin-list';
import { useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import { UnassignedUserDto } from './infrastructure/unassigned-user-dto';
import axios from 'axios';
import { Stack } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';

const UsersAdministration = () => {

    const history = useHistory();
    const theme = useThemeContext();
    const [unassignedUsers, setUnassignedUsers] = useState<UnassignedUserDto[]>([]);

    const navigateToEditUser = (userId: string) => history.push(Routes.getUserManagementEditUserUrl(userId));

    useEffect(() => {
        let canceled = false;

        const loadUnassignedUsers = async () => {
            const response = await axios.get<UnassignedUserDto[]>('/api/users/unassigned');

            if (!canceled) {
                setUnassignedUsers(response.data);
            }
        }

        loadUnassignedUsers();

        return () => { canceled = true; }
    }, []);

    const unassignedUsersStyle: CSSProperties = {
        maxHeight: '200px',
        overflowY: 'auto',
        paddingBottom: theme.spacing.l1
    }

    return (
        <Stack>
            {
                unassignedUsers && unassignedUsers.length &&
                <div style={unassignedUsersStyle}>
                    <UnassignedUsers users={unassignedUsers} onEditUser={navigateToEditUser} />
                </div>
            }


            <UsersAdminList navigateToEditUser={navigateToEditUser} />
        </Stack>
    )
}

export default UsersAdministration;
