import React, { useContext } from 'react';
import { ThemeContext } from '../../theming/theme-context';

export interface ReportSectionHeaderProperties {
    headerText: string;
}

export default function ReportSectionHeader(props: ReportSectionHeaderProperties): JSX.Element {

    const theme = useContext(ThemeContext);

    return (
        <h3
            style={{
                borderBottom: `2px solid ${theme.palette.themePrimary}`,
                paddingBottom: theme.spacing.s2
            }}
        >
            {props.headerText}
        </h3>
    );
}