import React from 'react';
import useThemeContext from 'app/theming/theme-context';
import { Stack, FontIcon } from '@fluentui/react';
import BackToReportsLink from '../back-to-reports-link';
import CenteredView from 'app/view-layout/centered-view';

export default function ReportFinishedSuccessfullyPage() {

    const theme = useThemeContext();

    return (
        <CenteredView maxWidth='600px'>
            <Stack
                horizontal
                tokens={{ childrenGap: theme.spacing.m }}
                verticalAlign='center'
                style={{ marginBottom: theme.spacing.l1 }}
            >
                <FontIcon iconName="BoxCheckmarkSolid" style={{ color: theme.palette.green, fontSize: 50 }}></FontIcon>
                <h1>Schadensanzeige nachgemeldet</h1>
            </Stack>
            <p>Ihre Schadensanzeige wurde erfolgreich nachgemeldet. Wir haben Ihnen die Anzeige als PDF-Dokument und alle weiteren Anhänge per Email gesendet. Bitte überprüfen Sie Ihr Emailpostfach (und ggfs. Ihren SPAM-Ordner).</p>
            <BackToReportsLink style={{ marginTop: theme.spacing.s1 }} />
        </CenteredView>
    );
}