import React from 'react';
import { Link } from '@fluentui/react';
import LinkProperties from './link-properties';

export default function ExternalLink(properties: LinkProperties): JSX.Element {

    return (
        <Link
            target='_blank'
            {...properties}
        >
            {properties.text}
        </Link>
    );
}
