import React, { useContext, useState } from "react";
import { ThemeContext } from "app/theming/theme-context";
import { TextField, Stack, PrimaryButton, Spinner } from "office-ui-fabric-react";
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios';
import { LoginDto } from './login-dto';
import { validateEmail, validateEmailExists } from 'app/forms/validation/validations';
import { useHistory } from "react-router-dom";
import { LoginSuccessfulDto } from "./login-successful-dto";
import { Role } from "../role";
import Routes from 'app//navigation/routes';
import { getApplyPasswordError, getEmailError } from "app/forms/validation/validation-errors";
import CenteredView from 'app/view-layout/centered-view';
import NavigationLink from "app/navigation/navigation-link";
import LoginProperties from './login-properties';

export default function LoginPage(props: LoginProperties) {

    const history = useHistory();
    const theme = useContext(ThemeContext);
    const { handleSubmit, errors, setError, control } = useForm();
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const onLoginSubmit = async (data: any) => {
        setIsLoggingIn(true);

        const loginDto: LoginDto = {
            email: data.email,
            password: data.password
        };

        try {
            const response = await axios.post<LoginSuccessfulDto>('/api/users/login', loginDto);
            setIsLoggingIn(false);
            props.onLogin(response.data.user, response.data.jwtToken);
            if (response.data.user.role === Role.AonAdmin) {
                history.push(Routes.userManagementUsers);
            } else {
                history.push(Routes.reports)
            }
        } catch (error) {
            setIsLoggingIn(false);
            const errorMsg: string = error.response ? error.response.data : '';
            if (errorMsg.startsWith('Your email')) {
                history.push(Routes.emailNotConfirmed, { params: { email: loginDto.email } });
            } else if (errorMsg.startsWith('Your account is not active')) {
                history.push(Routes.inactiveAccount);
            } else if (errorMsg.startsWith('Your account must first')) {
                history.push(Routes.unconfirmedAccount);
            } else if (errorMsg.startsWith('The provided')) {
                setError('password', 'invalid');
            } else {
                throw error;
            }
        }
    }

    return (
        <CenteredView>
            <form onSubmit={handleSubmit(onLoginSubmit)}>
                <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                    <h3 style={{ marginBottom: theme.spacing.m }}>Bitte loggen Sie sich ein</h3>

                    <Controller
                        as={
                            <TextField
                                label='Email'
                                type='email'
                                errorMessage={getEmailError(errors, 'email')}
                            />
                        }
                        control={control}
                        name='email'
                        rules={{
                            required: true,
                            validate: {
                                isEmail: (value: string) => validateEmail(value),
                                exists: async (value: string) => await validateEmailExists(value, true)
                            }
                        }}
                    />

                    <Controller
                        as={
                            <TextField
                                label='Passwort'
                                errorMessage={getApplyPasswordError(errors, 'password')}
                                type='password'
                            />
                        }
                        name="password"
                        control={control}
                        rules={{ required: true }}
                    />

                    <PrimaryButton
                        style={{ marginTop: theme.spacing.l1 }}
                        text="Anmelden"
                        type="submit"
                    />

                    {
                        isLoggingIn &&
                        <Spinner style={{ marginTop: theme.spacing.m }} label="Sie werden angemeldet..." />
                    }

                    <Stack style={{ marginTop: theme.spacing.l2 }}>
                        <NavigationLink text='Passwort vergessen' to={Routes.requestPasswordReset} />
                        <NavigationLink text='Neu hier? Benutzerkonto erstellen' to={Routes.signUp} />
                    </Stack>
                </Stack>
            </form>
        </CenteredView>
    );
}
