import React, { CSSProperties } from 'react';
import { Icon } from '@fluentui/react';

export default function buildBooleanTemplate(value: boolean): React.ReactNode {

  const checkedStyle: CSSProperties = {
    color: 'green',
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'block',
    textAlign: 'left'
  }

  const uncheckedStyle: CSSProperties = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'block',
    textAlign: 'left'
  }

  if (value) {
    return (
      <span style={checkedStyle}>
        <Icon iconName='accept' />
      </span>
      
    );
  }

  return (
    <span style={uncheckedStyle}>
      <Icon iconName='cancel' />
    </span>
    
  );
}
