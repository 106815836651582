import React, { useContext } from 'react';
import { Stack } from '@fluentui/react';
import ChildrenProperties from './children-properties';
import { ThemeContext } from 'app/theming/theme-context';

export interface CenteredViewProperties extends ChildrenProperties {
    maxWidth?: string;
}

export default function CenteredView(props: CenteredViewProperties): JSX.Element {

    const theme = useContext(ThemeContext);
    const stackPadding = theme.spacing.m;

    return (
        <Stack
            style={{
                flexGrow: 1,
                paddingTop: stackPadding,
                paddingBottom: stackPadding
            }}
            horizontalAlign='center'
            verticalAlign='center'
        >
            <div
                style={{
                    padding: theme.spacing.l2,
                    backgroundColor: theme.palette.white,
                    boxShadow: theme.effects.elevation4,
                    width: '100%',
                    maxWidth: props.maxWidth || '360px'
                }}
            >
                {props.children}
            </div>
        </Stack>
    );

}