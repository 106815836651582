import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ColumnSortInfo } from 'app/lists/column-sort-info';
import { IColumn, DetailsList, Stack, SelectionMode, Selection, CommandBar, IObjectWithKey } from '@fluentui/react';
import { buildAttachmentColumns } from './build-attachment-columns';
import { AttachmentType } from '../edit-report/attachment-type';
import ExtendedAttachmentInfo from './extended-attachment-info';

export interface AttachmentListProperties {
    attachments: ExtendedAttachmentInfo[];
    onDeleteAttachment: (attachment: ExtendedAttachmentInfo) => void;
    onEditAttachment: (attachment: ExtendedAttachmentInfo) => void;
}

export default function AttachmentList(props: AttachmentListProperties): JSX.Element {

    const [attachmentColumns, setAttachmentColumns] = useState<IColumn[]>([]);
    const [sortColumn, setSortColumn] = useState<ColumnSortInfo | null>(null);
    const [selectedAttachment, setSelectedAttachment] = useState<ExtendedAttachmentInfo | null>(null);

    const commandBarItems = useListCommands(selectedAttachment, props.onDeleteAttachment, props.onEditAttachment);

    const onColumnClick = useCallback((_: any, column: IColumn) => {
        const sortInfo: ColumnSortInfo = {
            sortField: column.key!,
            isSortedDescending: !column.isSortedDescending
        }
        setSortColumn(sortInfo);
    }, []);

    useEffect(
        () => {
            const columns = buildAttachmentColumns(onColumnClick, sortColumn);
            setAttachmentColumns(columns);
        },
        [sortColumn, onColumnClick]
    );

    const selection: Selection = useMemo(
        () => new Selection({
            onSelectionChanged: () => onSelectedAttachmentChanged(selection.getSelection())
        }),
        []
    );

    const onSelectedAttachmentChanged = (selectedObjects: IObjectWithKey[]) => {
        if (selectedObjects && selectedObjects.length) {
            setSelectedAttachment(selectedObjects[0] as ExtendedAttachmentInfo);
        } else {
            setSelectedAttachment(null);
        }
    }

    return (
        <Stack>
            <CommandBar items={commandBarItems} />
            <DetailsList
                styles={{ root: { height: '100%', maxWidth: '100%', minWidth: '1px' } }}
                items={props.attachments}
                columns={attachmentColumns}
                selectionMode={SelectionMode.single}
                selection={selection}
            />
        </Stack>
    );
}

const useListCommands = (
    selectedAttachment: ExtendedAttachmentInfo | null,
    onDeleteAttachment: (attachment: ExtendedAttachmentInfo) => void,
    onEditAttachment: (attachment: ExtendedAttachmentInfo) => void
) => {
    const commands = useMemo(() => [
        {
            key: 'edit',
            text: 'Bearbeiten',
            disabled: !selectedAttachment || selectedAttachment.attachmentType === AttachmentType.DamageReport,
            onClick: () => onEditAttachment(selectedAttachment!),
            iconProps: { iconName: 'edit' }
        },
        {
            key: 'delete',
            text: 'Löschen',
            disabled: !selectedAttachment || selectedAttachment.attachmentType === AttachmentType.DamageReport,
            onClick: () => onDeleteAttachment(selectedAttachment!),
            iconProps: { iconName: 'delete' }
        }
    ], [selectedAttachment, onDeleteAttachment, onEditAttachment]);

    return commands;
}
