export enum Role {
    AonAdmin,
    OrganizationAdmin,
    OrganizationMember
}

export const Roles: Role[] = [
    Role.OrganizationMember,
    Role.OrganizationAdmin,
    Role.AonAdmin
]

export function getRoleString(role: Role): string {
    switch (role) {
        case Role.AonAdmin: return 'AON Admin'
        case Role.OrganizationAdmin: return 'Melder Händler'
        case Role.OrganizationMember: return 'Melder'
        default: throw new Error(`The role "${role}" is unknown.`);
    }
} 
