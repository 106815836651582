import React, { useContext } from 'react';
import { ThemeContext } from 'app/theming/theme-context';

export interface ThemedSelectErrorProperties {
    errorMessage: string | JSX.Element;
    marginTop?: string | number;
}

export default function ThemedSelectError(props: ThemedSelectErrorProperties): JSX.Element {

    const theme = useContext(ThemeContext);

    if (typeof props.errorMessage === 'string') {
        return (
            <p
                className='ms-TextField-errorMessage'
                style={{
                    marginTop: props.marginTop || theme.spacing.s2,
                    color: theme.semanticColors.errorText
                }}
            >
                <span>
                    {props.errorMessage}
                </span>
            </p>
        );
    }

    return (
        <div style={{ marginTop: props.marginTop || theme.spacing.s2 }}>
            {props.errorMessage}
        </div>
    );
}
