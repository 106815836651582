import { CheckPasswordDto } from "../../user-management/sign-up/check-password-dto";
import axios from 'axios';
import { EmailExistsDto } from "../../user-management/sign-up/email-exists-dto";

export function validateEmail(value: string): boolean {
    return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(value));
};

export async function validateEmailExists(email: string, shouldExist: boolean): Promise<boolean> {
    const dto = await axios.get<EmailExistsDto>(
        'api/users/checkEmail',
        {
            params: {
                email: email
            }
        }
    );
    return dto.data.isExistingEmailAddress === shouldExist;
}

export function isDigit(value: string): boolean {
    return value >= '0' && value <= '9';
}

export function validateZipCode(value: string): boolean {
    if (!value) {
        return true;
    }

    return (
        value.length === 5 &&
        isDigit(value[0]) &&
        isDigit(value[1]) &&
        isDigit(value[2]) &&
        isDigit(value[3]) &&
        isDigit(value[4])
    );
}

export function validateIBAN(value: string): boolean {
    if (!value) {
        return true;
    }

    if (value.length !== 22 || value[0] !== 'D' || value[1] !== 'E') {
        return false;
    }

    for (let i = 2; i < value.length; i++) {
        const character = value[i];
        if (!isDigit(character)) {
            return false;
        }
    }

    return true;
}

export async function validatePassword(password: string, firstName: string, lastName: string, email: string): Promise<CheckPasswordDto | null> {
    if (!email || !firstName || !lastName) {
        return null;
    }

    const response = await axios.get<CheckPasswordDto>(
        '/api/users/checkPassword',
        {
            params: {
                password: password,
                email: email,
                firstName: firstName,
                lastName: lastName
            }
        }
    );

    return response.data;
}

export function hasNoForbiddenCharacter(value: string, forbiddenCharacters: string[]): boolean {
    for (let i = 0; i < value.length; ++i) {
        if (forbiddenCharacters.some(char => char === value[i])) {
            return false;
        }
    }
    return true;
}

export function hasErrors(errors: any): boolean {
    if (!errors) {
        return false;
    }

    return Object.getOwnPropertyNames(errors).length > 0;
}
