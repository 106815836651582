import React, { useContext, useState } from 'react';
import { AttachmentInfo } from '../edit-report/attachment-info';
import { Stack, Label, PrimaryButton, DefaultButton } from '@fluentui/react';
import { ThemeContext } from 'app/theming/theme-context';
import Select from 'react-select';
import { UploadableAttachmentTypes, getAttachmentTypeName } from '../edit-report/attachment-type';
import buildReactSelectOption from 'app/forms/select-options/build-react-select-option';
import { ChangeAttachmentDto } from './change-attachment-dto';
import axios from 'axios';
import { buildAttachmentTypeOption } from './build-attachment-type-option';
import ExtendedAttachmentInfo, { convertToExtendedAttachmentInfos } from './extended-attachment-info';
import useThemedSelectStyle from 'app/forms/themed-select/use-themed-select-style';

export interface EditAttachmentProperties {
    attachment: ExtendedAttachmentInfo;
    reportId: string;
    onClose: () => void;
    onAttachmentChanged: (newAttachments: ExtendedAttachmentInfo[]) => void;
}

export default function EditAttachment(props: EditAttachmentProperties): JSX.Element {

    const theme = useContext(ThemeContext);
    const [selectedAttachmentType, setSelectedAttachmentType] = useState(buildReactSelectOption(props.attachment.attachmentType, getAttachmentTypeName(props.attachment.attachmentType)));
    const [isSaving, setIsSaving] = useState(false);
    const themedSelectStyle = useThemedSelectStyle();

    const saveAttachmentType = async () => {
        if (isSaving) {
            return;
        }

        try {
            if (props.attachment.attachmentType === selectedAttachmentType.data) {
                props.onClose();
                return;
            }

            const dto: ChangeAttachmentDto = {
                reportId: props.reportId,
                attachmentName: props.attachment.name,
                type: selectedAttachmentType.data
            }

            setIsSaving(true);
            const response = await axios.put<AttachmentInfo[]>('/api/reports/changeAttachmentType', dto);
            props.onAttachmentChanged(convertToExtendedAttachmentInfos(response.data));
            props.onClose();
        } catch (error) {
            // TODO: show error here
            console.error('Could not change document type for attachment', error);
            setIsSaving(false);
        }
    }

    return (
        <Stack>
            <h3 style={{ color: theme.palette.black }}>Anhang ändern</h3>
            <h5 style={{ color: 'gray' }}>{props.attachment.name}</h5>

            <div style={{ marginTop: theme.spacing.m }}>
                <Label>Typ</Label>
                <Select
                    options={UploadableAttachmentTypes.map(type => buildReactSelectOption(type, getAttachmentTypeName(type)))}
                    value={selectedAttachmentType}
                    onChange={(selection) => setSelectedAttachmentType(selection as any)}
                    formatOptionLabel={buildAttachmentTypeOption}
                    styles={themedSelectStyle}
                />
            </div>

            <Stack
                horizontal={true}
                horizontalAlign='end'
                tokens={{ childrenGap: 8, padding: `${theme.spacing.l1} 0 0 0` }}
            >
                <DefaultButton
                    text='Abbrechen'
                    onClick={props.onClose}
                />
                <PrimaryButton
                    text='Speichern'
                    onClick={saveAttachmentType}
                />
            </Stack>
        </Stack>
    )
}
