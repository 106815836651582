import React, { useEffect, useState } from 'react';

export default function useDebouncedState<T>(initialValue: T, delayInMilliseconds: number = 250): [T, T, React.Dispatch<React.SetStateAction<T>>] {
    const [immediateState, setImmediateState] = useState(initialValue);
    const [debouncedState, setDebouncedState] = useState(initialValue);

    useEffect(
        () => {
            const timeoutId = setTimeout(
                () => setDebouncedState(immediateState),
                delayInMilliseconds
            );
            return () => clearTimeout(timeoutId);
        },
        [immediateState, delayInMilliseconds]
    );

    return [debouncedState, immediateState, setImmediateState];
}