import { PasswordValidationResult, CheckPasswordDto } from "app/user-management/sign-up/check-password-dto";

export function getRequiredError(errors: any, name: string, message: string): string {
    return errors[name] && errors[name].type === 'required'
        ? message
        : '';
}

export function getPasswordError(errors: any, name: string, checkPasswordDto: CheckPasswordDto | null) {

    const passwordErrors = errors[name];

    if (passwordErrors && passwordErrors.type === 'required') {
        return 'Bitte Passwort angeben';
    }

    if (!checkPasswordDto || checkPasswordDto.result === PasswordValidationResult.Valid) {
        return '';
    }

    const validationResult = checkPasswordDto.result;

    if (validationResult === PasswordValidationResult.EqualToEmail) {
        return 'Ihr Passwort darf nicht identisch zu Ihrer Emailadresse sein.';
    }
    if (validationResult === PasswordValidationResult.EqualToName) {
        return 'Ihr Passwort und Ihr Name dürfen nicht gleich sein.';
    }
    if (validationResult === PasswordValidationResult.MissingCharacterCategories) {
        return 'Ihr Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten.';
    }
    if (validationResult === PasswordValidationResult.PasswordTooLong) {
        return `Ihr Passwort ist zu lang. Bitte verwenden Sie maximal ${checkPasswordDto.maximumPasswordLength} Zeichen.`;
    }
    else {
        return `Ihr Passwort ist zu kurz. Bitte verwenden Sie wenigstens ${checkPasswordDto.minimumPasswordLength} Zeichen.`;
    }

}

export function getApplyPasswordError(errors: any, name: string) {
    if (!errors[name]) {
        return '';
    } else if (errors[name].type === 'required') {
        return 'Bitte Passwort angeben';
    } else if (errors[name].type === 'invalid') {
        return 'Falsches Passwort';
    }
    return 'Bitte überprüfen Sie Ihre Eingabe';
}

export function getEmailError(errors: any, name: string): string {
    if (!errors[name]) {
        return '';
    } else if (errors[name].type === 'required') {
        return 'Bitte Email angeben';
    } else if (errors[name].type === 'isEmail') {
        return 'Ungültige Email Addresse';
    } else if (errors[name].type === 'used') {
        return 'Diese Email ist bereits vergeben';
    } else if (errors[name].type === 'exists') {
        return 'Diese Email ist nicht registriert';
    }
    return 'Bitte überprüfen Sie Ihre Eingabe';
}

export function getVinError(errors: any, name: string): string {
    if (!errors[name]) {
        return ''
    } else if (errors[name].type === 'required') {
        return 'Bitte FIN angeben';
    } else if (errors[name].type === 'minLength' || errors[name].type === 'maxLength') {
        return 'FIN muss 17 Zeichen lang sein';
    } else if (errors[name].type === 'forbiddenCharacter') {
        return 'FIN darf kein "I", "O" oder "Q" enthalten';
    }
    return 'Bitte überprüfen Sie Ihre Eingabe';
}

export function getIbanError(errors: any, name: string): string {
    if (errors[name] && errors[name].type === 'required') {
        return 'Bitte IBAN angeben';
    }
    if (errors[name] && errors[name].type === 'validate') {
        return 'Die IBAN muss mit DE anfangen mit 20 darauffolgenden Ziffern';
    }
    return '';
}

export function getZipCodeError(errors: any, name: string): string {
    if (errors[name] && errors[name].type === 'required') {
        return 'Bitte Postleitzahl angeben';
    }
    if (errors[name] && errors[name].type === 'validate') {
        return 'Postleitzahl muss aus fünf Zahlen bestehen';
    }
    return '';
}

export function getAmountOfDamageError(errors: any): string {
    if (!errors['amountOfDamage']) {
        return '';
    }
    const errType = errors['amountOfDamage'].type;
    switch (errType) {
        case 'required': return 'Bitte Schadenshöhe eingeben'
        case 'min': return 'Die Schadenshöhe darf nicht negativ sein'
        case 'validate': return 'Bitte geben Sie ein Ganzzahl oder eine Dezimalzahl mit maximal zwei Stellen nach dem Komma ein'
        default: return ''
    }
}
