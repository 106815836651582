import React from 'react';
import { Toggle } from '@fluentui/react';
import { darkTheme } from './themes';
import useThemeContext from './theme-context';

export interface SwitchThemeButtonProperties {
    switchTheme: () => void;
}

export default function SwitchThemeButton(props: SwitchThemeButtonProperties) {

    const currentTheme = useThemeContext();

    return (
        <Toggle
            inlineLabel
            label="Dunkles Design"
            onChange={props.switchTheme}
            role="checkbox"
            defaultChecked={currentTheme === darkTheme}
            styles={{
                root: {
                    marginBottom: 0
                }
            }} />
    );
}
