export enum SeverityCode {
    LessThanThreeCentimeters = 1,
    ThreeToTenCentimeters = 2,
    TenToTwentyCentimeters = 3,
    TwentyToThirtyCentimeters = 4,
    MoreThanThirtyCentimeters = 5,
    SevereDamage = 6
}

export const severityCodeOptions = [
    { label: 'Weniger als 3cm', value: SeverityCode.LessThanThreeCentimeters },
    { label: '3cm bis 10cm', value: SeverityCode.ThreeToTenCentimeters },
    { label: '10cm bis 20cm', value: SeverityCode.TenToTwentyCentimeters },
    { label: '20cm bis 30cm', value: SeverityCode.TwentyToThirtyCentimeters },
    { label: 'Mehr als 30cm', value: SeverityCode.MoreThanThirtyCentimeters },
    { label: 'Gravierender Schaden', value: SeverityCode.SevereDamage }
];
