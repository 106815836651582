import React, { useState } from 'react';
import { Stack, TextField, PrimaryButton, Checkbox, Spinner, SpinnerSize } from '@fluentui/react';
import { useForm, Controller } from 'react-hook-form';
import useThemeContext from 'app/theming/theme-context';
import { validateEmail, validatePassword, validateZipCode, validateIBAN } from 'app/forms/validation/validations';
import axios from 'axios';
import { PasswordValidationResult, CheckPasswordDto } from './check-password-dto';
import { SignUpDto } from './sign-up-dto';
import { useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import { getRequiredError, getPasswordError, getEmailError, getIbanError, getZipCodeError } from 'app/forms/validation/validation-errors';
import CenteredView from 'app/view-layout/centered-view';

export default function SignUpPage(): JSX.Element {

    const theme = useThemeContext();
    const history = useHistory();
    const [isRetailerNumberKnown, setIsRetailerNumberKnown] = useState(true);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [passwordDto, setPasswordDto] = useState<CheckPasswordDto | null>(null);

    const { handleSubmit, control, setValue, errors, getValues, setError } = useForm();

    const onRegisterSubmit = async (data: any) => {
        if (isSigningUp) {
            return;
        }
        setIsSigningUp(true);

        const dto = data as SignUpDto;
        try {
            await axios.put('/api/users/signUp', dto);
            history.push(Routes.accountCreated);
        } catch (error) {
            setIsSigningUp(false);
            const errorMessage: string = error.response ? error.response.data : '';
            if (errorMessage.startsWith('There is no organization')) {
                setError('retailerNumber', 'notFound');
            } else if (errorMessage.startsWith('There already is an organization')) {
                setError('retailerNumber', 'alreadyExists');
            } else if (errorMessage.startsWith('The email address')) {
                setError('email', 'used');
            } else {
                console.error('service returned unknown error message on signup.', error);
            }
        }
    };

    const checkPassword = async (password: string): Promise<boolean> => {
        if (!password) {
            return true;
        }
        const currentValues = getValues();
        const passwordDto = await validatePassword(password, currentValues.firstName, currentValues.lastName, currentValues.email);
        setPasswordDto(passwordDto);
        return passwordDto != null && passwordDto.result === PasswordValidationResult.Valid;
    }

    const isOrganizationNumberKnownChanged = (value: boolean | undefined) => {
        const newValue = !!value;
        if (newValue) {
            setValue('organizationName', undefined);
            setValue('organizationStreet', undefined);
            setValue('organizationZipCode', undefined);
            setValue('organizationLocation', undefined);
            setValue('organizationIban', undefined);
        }
        setIsRetailerNumberKnown(newValue);
    }

    return (
        <CenteredView>
            <form
                autoComplete='new-password' // This works for Firefox, but not for Chromium-based browsers
                onSubmit={handleSubmit(onRegisterSubmit)}
            >
                <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                    <h3 style={{ marginBottom: theme.spacing.m }}>Neuen Account erstellen</h3>

                    <Controller
                        as={
                            <TextField
                                label="Vorname *"
                                errorMessage={getRequiredError(errors, 'firstName', 'Bitte Vorname angeben')}
                            />
                        }
                        name="firstName"
                        control={control}
                        rules={{ required: true }}
                    />

                    <Controller
                        as={
                            <TextField
                                label="Nachname *"
                                errorMessage={getRequiredError(errors, 'lastName', 'Bitte Nachname angeben')}
                            />
                        }
                        name="lastName"
                        control={control}
                        rules={{ required: true }}
                    />

                    <Controller
                        as={
                            <TextField
                                label="Email *"
                                errorMessage={getEmailError(errors, 'email')}
                            />
                        }
                        name="email"
                        control={control}
                        rules={{
                            required: true,
                            validate: {
                                isEmail: (value: string) => validateEmail(value)
                            }
                        }}
                    />

                    <Controller
                        as={
                            <TextField
                                label="Passwort *"
                                type='password'
                                autoComplete='new-password'
                                errorMessage={getPasswordError(errors, 'password', passwordDto as CheckPasswordDto)}
                            />
                        }
                        name="password"
                        control={control}
                        rules={{
                            required: true,
                            validate: async (value: string) => checkPassword(value)
                        }}
                    />

                    <div style={{ marginTop: theme.spacing.l2 }}>
                        <Checkbox
                            label='Händler wurde bereits im Portal erfasst'
                            onChange={(_, value) => isOrganizationNumberKnownChanged(value)}
                            checked={isRetailerNumberKnown}
                        />
                    </div>

                    {
                        !isRetailerNumberKnown &&
                        <p>Bitte füllen Sie alle Informationen zu Ihrem Unternehmen aus.</p>
                    }

                    <Controller
                        as={
                            <TextField
                                label="Händlernummer *"
                                errorMessage={getRetailerNumberError(errors)}
                            />
                        }
                        name="retailerNumber"
                        control={control}
                        rules={{
                            required: true
                        }}
                    />

                    {
                        !isRetailerNumberKnown &&
                        <div>
                            <Controller
                                as={
                                    <TextField
                                        label='Händlername *'
                                        errorMessage={getRequiredError(errors, 'organizationName', 'Bitte Händlernamen angeben')}
                                    />
                                }
                                name='organizationName'
                                control={control}
                                rules={{ required: true }}
                            />
                            <Controller
                                as={
                                    <TextField
                                        label='Straße *'
                                        errorMessage={getRequiredError(errors, 'organizationStreet', 'Bitte Händlerstraße angeben')}
                                    />
                                }
                                name='organizationStreet'
                                rules={{ required: true }}
                                control={control}
                            />
                            <Controller
                                as={
                                    <TextField
                                        label='Postleitzahl *'
                                        errorMessage={getZipCodeError(errors, 'organizationZipCode')}
                                    />
                                }
                                name='organizationZipCode'
                                control={control}
                                rules={{
                                    required: true,
                                    validate: value => validateZipCode(value)
                                }}
                            />
                            <Controller
                                as={
                                    <TextField
                                        label='Ort *'
                                        errorMessage={getRequiredError(errors, 'organizationLocation', 'Bitte Ort angeben')}
                                    />
                                }
                                name='organizationLocation'
                                control={control}
                                rules={{ required: true }}
                            />
                            <Controller
                                as={
                                    <TextField
                                        label='IBAN'
                                        errorMessage={getIbanError(errors, 'organizationIban')}
                                    />
                                }
                                name='organizationIban'
                                control={control}
                                rules={{ validate: value => validateIBAN(value) }}
                            />
                        </div>
                    }

                    <PrimaryButton
                        style={{ marginTop: theme.spacing.l1 }}
                        text="Registrieren"
                        type="submit"
                    />

                    {
                        isSigningUp &&
                        <Spinner
                            style={{ marginTop: theme.spacing.m }}
                            label="Sie werden registriert..."
                            size={SpinnerSize.large}
                        />
                    }
                </Stack>
            </form>
        </CenteredView>
    )
}

function getRetailerNumberError(errors: any): string {
    const { retailerNumber } = errors;
    if (!retailerNumber) {
        return '';
    }

    if (retailerNumber.type === 'notFound') {
        return 'In unserem System ist kein Händler mit dieser Nummer vorhanden. Bitte überprüfen Sie, ob die Händlernummer richtig ist, oder deaktivieren Sie das Häckchen "Händlernummer im System bekannt" und registrieren Sie sich als neuer Händler.';
    }
    if (retailerNumber.type === 'alreadyExists') {
        return 'Es gibt bereits einen Händler mit dieser Nummer. Bitte überprüfen Sie, ob die Händlernummer richtig ist, oder aktivieren Sie das Häckchen "Händlernummer im System bekannt".';
    }
    if (retailerNumber.type === 'required') {
        return 'Bitte geben Sie die Händlernummer an';
    }

    throw new Error(`The error type ${retailerNumber.type} is unknown for field retailerNumber.`);
}
