import axios from 'axios';

export default async function download(url: string, fileName: string, mimeType: string): Promise<void> {
    const response = await axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });
    const fileUrl = URL.createObjectURL(file);

    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.style.display = 'none';
    anchor.download = fileName;
    anchor.type = mimeType;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(fileUrl);
}
