import React, { CSSProperties, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Stack, Spinner, PrimaryButton, TextField } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';
import axios from 'axios';
import { ConfirmPasswordResetDto } from './confirm-password-reset-dto';
import { useForm, Controller } from 'react-hook-form';
import { CheckPasswordDto } from '../sign-up/check-password-dto';
import Routes from 'app/navigation/routes';
import { getPasswordError } from 'app/forms/validation/validation-errors';
import CenteredView from 'app/view-layout/centered-view';

enum ResetStage {
    ResetInput,
    IsResetting,
    ResetSuccess,
    TokenInvalid
}

export default function ResetPasswordPage(): JSX.Element {

    const [resetStage, setResetStage] = useState(ResetStage.ResetInput);
    const [passwordDto, setPasswordDto] = useState<CheckPasswordDto | null>(null);
    const { handleSubmit, errors, control } = useForm();
    const theme = useThemeContext();
    const history = useHistory();
    const location = useLocation();

    const messageContainerStyle: CSSProperties = {
        backgroundColor: theme.palette.white,
        boxShadow: theme.effects.elevation4,
        padding: theme.spacing.l2,
        maxWidth: '360px',
        width: '100%'
    };

    const navigateToLogin = useCallback(() => history.push(Routes.login), [history]);

    const submitResetPassword = async (data: any) => {
        if (resetStage === ResetStage.IsResetting) {
            return;
        }
        setResetStage(ResetStage.IsResetting);

        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (!token) {
            setResetStage(ResetStage.TokenInvalid)
            return;
        }

        const confirmDto: ConfirmPasswordResetDto = {
            newPassword: data.password,
            token: token
        }
        try {
            await axios.put('/api/users/confirmPasswordReset', confirmDto);
            setPasswordDto(null);
            setResetStage(ResetStage.ResetSuccess);
        } catch (error) {
            if (error.response.data && error.response.data.result) {
                const passwordDto = error.response.data as CheckPasswordDto;
                setPasswordDto(passwordDto);
                setResetStage(ResetStage.ResetInput);
                return;
            }
            setResetStage(ResetStage.TokenInvalid);
        }
    }

    useEffect(
        () => {
            if (resetStage === ResetStage.ResetSuccess) {
                const navTimeout = setTimeout(() => {
                    navigateToLogin();
                }, 3000);
                return () => clearTimeout(navTimeout);
            }
        },
        [resetStage, navigateToLogin]
    );

    return (
        <CenteredView>
            <Stack style={messageContainerStyle}>
                {
                    resetStage === ResetStage.TokenInvalid &&
                    <div>
                        <h3>Dieser Link ist nicht mehr gültig.</h3>
                        <p>Auf der Login-Seite könnnen Sie Ihr Passwort erneut zurücksetzen.</p>
                        <PrimaryButton
                            style={{ marginTop: theme.spacing.l1 }}
                            text="Zurück zum Login"
                            onClick={navigateToLogin}
                        />
                    </div>
                }
                {
                    resetStage === ResetStage.ResetSuccess &&
                    <div>
                        <h3>Ihr Passwort wurde geändert.</h3>
                        <p>Sie werden in Kürze zum Login weitergeleitet.</p>
                    </div>
                }
                {
                    (resetStage === ResetStage.ResetInput || resetStage === ResetStage.IsResetting) &&

                    <form onSubmit={handleSubmit(submitResetPassword)}>
                        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
                            <h3 style={{ marginBottom: theme.spacing.m }}>Neues Passwort eingeben</h3>
                            <Controller
                                as={
                                    <TextField
                                        label='Neues Passwort'
                                        type='password'
                                        errorMessage={getPasswordError(errors, 'password', passwordDto as CheckPasswordDto)}
                                    />
                                }
                                control={control}
                                name="password"
                                rules={{ required: true }}
                            />
                            <PrimaryButton
                                style={{ marginTop: theme.spacing.l1 }}
                                text="Passwort zurücksetzen"
                                type="submit"
                            />
                            {
                                resetStage === ResetStage.IsResetting &&
                                <Spinner style={{ marginTop: theme.spacing.m }} label="Passwort wird gesetzt..." />
                            }
                        </Stack>
                    </form>
                }
            </Stack>
        </CenteredView>
    )
}
