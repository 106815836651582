import React, { useState, useEffect, useContext, useCallback } from 'react';
import CaseWorkerEmailDto from './case-worker-email-dto';
import axios from 'axios';
import { Stack, MessageBar, MessageBarType, TextField, PrimaryButton, IStackTokens, Spinner, SpinnerSize } from '@fluentui/react';
import { useForm, Controller } from 'react-hook-form';
import { validateEmail } from 'app/forms/validation/validations';
import { getEmailError } from 'app/forms/validation/validation-errors';
import { ThemeContext } from 'app/theming/theme-context';

interface Notification {
    message: string;
    type: MessageBarType
}

export default function CaseWorkerSettings(): JSX.Element {

    const [isCallingService, setIsCallingService] = useState<boolean>(true);
    const [notification, setNotification] = useState<Notification | null>(null);
    const [changeVector, setChangeVector] = useState<string | null>(null);
    const { handleSubmit, errors, control, formState, reset } = useForm({ mode: 'onBlur', defaultValues: { email: '' } });
    const theme = useContext(ThemeContext);

    const loadEmail = useCallback(
        async () => {
            setNotification(null);
            setIsCallingService(true);

            try {
                const response = await axios.get<CaseWorkerEmailDto>("/api/caseWorkerEmailAddress");
                setChangeVector(response.data.changeVector);
                reset({ email: response.data.emailAddress });
            } catch (error) {
                setNotification({ message: "Der Service ist aktuell nicht erreichbar.", type: MessageBarType.error });
            } finally {
                setIsCallingService(false);
            }
        },
        [setNotification, setIsCallingService, setChangeVector, reset]
    );

    async function submit(data: any): Promise<void> {
        setNotification(null);
        setIsCallingService(true);

        const dto: CaseWorkerEmailDto = {
            emailAddress: data.email,
            changeVector: changeVector as string
        };

        try {
            const response = await axios.put<CaseWorkerEmailDto>("/api/caseWorkerEmailAddress/update", dto);
            setChangeVector(response.data.changeVector);
            reset({ email: response.data.emailAddress });
            setNotification({ message: `Die Sachbearbeiteradresse wurde erfolgreich auf ${response.data.emailAddress} geändert.`, type: MessageBarType.success });
        }
        catch (error) {
            if (error.response && error.response.status === 409) { // Concurrency issue
                setNotification({ message: 'Jemand anders hat in der Zwischenzeit die Email-Adresse aktualisiert. Bitte laden Sie die Seite erneut und überprüfen Sie die Änderung.', type: MessageBarType.severeWarning });
            } else {
                setNotification({ message: 'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.', type: MessageBarType.error });
            }
        } finally {
            setIsCallingService(false);
        }

    }

    function dismissNotification(): void {
        setNotification(null);
    }

    useEffect(() => { loadEmail(); }, [loadEmail]);

    const stackProps: IStackTokens = {
        childrenGap: theme.spacing.m
    };

    return (
        <Stack tokens={stackProps}>
            {notification &&
                <MessageBar
                    messageBarType={notification.type}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={dismissNotification}
                >
                    {notification.message}
                </MessageBar>
            }
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 ms-xxl2">
                        <form onSubmit={handleSubmit(submit)}>
                            <Stack tokens={stackProps}>
                                <Controller
                                    as={
                                        <TextField
                                            label="Emailadresse"
                                            errorMessage={getEmailError(errors, 'email')}
                                        />
                                    }
                                    name="email"
                                    rules={{
                                        required: true,
                                        validate: {
                                            isEmail: (value: string) => validateEmail(value)
                                        }
                                    }}
                                    control={control}
                                />

                                <PrimaryButton
                                    text="Speichern"
                                    type="submit"
                                    allowDisabledFocus
                                    disabled={isCallingService || !formState.dirty || !formState.isValid}
                                />
                                {
                                    isCallingService &&
                                    <Spinner size={SpinnerSize.large} />
                                }
                            </Stack>
                        </form>
                    </div>
                </div>
            </div>
        </Stack>
    );
};
