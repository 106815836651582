import React, { useEffect } from 'react';
import CenteredView from 'app/view-layout/centered-view';
import { Stack, Spinner, SpinnerSize } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import axios from 'axios';
import { LoginSuccessfulDto } from './login-successful-dto';
import LoginProperties from './login-properties';
import useUserContext from '../user-context';

export default function AutoLogin(props: LoginProperties): JSX.Element {

    const user = useUserContext();
    const history = useHistory();
    const location = useLocation();

    useEffect(
        () => {

            if (user) {
                return;
            }

            const userToken = localStorage.getItem('userToken');
            if (!userToken) {
                history.push(Routes.login);
            }

            axios
                .get<LoginSuccessfulDto>(
                    '/api/users/automaticLogin',
                    { headers: { 'Authorization': 'Bearer ' + userToken } }
                )
                .then(response => {
                    let targetUrl = Routes.home;
                    const locationState = location.state as any;
                    if (locationState && locationState.from) {
                        targetUrl = locationState.from;
                    }
                    props.onLogin(response.data.user, response.data.jwtToken);
                    history.replace(targetUrl);
                })
                .catch(() => history.push(Routes.login));
        },
        [user, history, location, props]
    );

    return (
        <CenteredView>
            <Stack
                verticalAlign='center'
                horizontalAlign='center'
            >
                <Spinner size={SpinnerSize.large} />
            </Stack>
        </CenteredView>
    );
}
