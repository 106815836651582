import React, { useCallback, useState, useEffect } from 'react';
import { Stack, DetailsList, IColumn, SelectionMode, Selection, IObjectWithKey, CommandBar, ICommandBarItemProps, SearchBox } from '@fluentui/react';
import { OrganizationDto } from './infrastructure/organization-dto';
import axios from 'axios';
import { buildOrganizationColumns } from './build-organization-columns';
import { ColumnSortInfo } from 'app/lists/column-sort-info';
import { useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';

const pageSize: number = 40;

const OrganizationsAdministration = () => {

    const [organizations, setOrganizations] = useState<(OrganizationDto | null)[]>([null]);
    const [searchTerm, setSeachTerm] = useState<string>();
    const [organizationColumns, setOrganizationColumns] = useState<IColumn[]>([]);
    const [sortColumn, setSortColumn] = useState<ColumnSortInfo | null>(null);
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationDto | null>(null);

    const history = useHistory();

    const navigateToNewOrganization = () => history.push(Routes.userManagementNewOrganization);
    const navigateToEditOrganization = (orgId: string) => history.push(Routes.getUserManagementEditOrganizationUrl(orgId));

    const loadOrganizations = useCallback(async (index: number, appendToExisting: boolean = true) => {
        if (!appendToExisting) {
            setOrganizations([]);
        }

        const response = await axios.get<OrganizationDto[]>('/api/organizations', {
            params: {
                skip: index,
                take: pageSize + 1,
                sortField: sortColumn ? sortColumn.sortField : null,
                isAscendingSortOrder: sortColumn ? !sortColumn.isSortedDescending : null,
                searchTerm: searchTerm
            }
        });

        const loadedOrganizations: (OrganizationDto | null)[] =
            response.data.length > pageSize
                ? [...response.data.slice(0, pageSize), null]
                : response.data;

        const newOrganizations = appendToExisting
            ? [...organizations.slice(0, organizations.length - 1), ...loadedOrganizations]
            : loadedOrganizations;

        setOrganizations(newOrganizations);
    }, [organizations, sortColumn, searchTerm]);

    const onRenderMissingItem = (index?: number) => {
        if (index != null) {
            loadOrganizations(index);
        }
        return <></>;
    };

    const onColumnClick = useCallback((_: any, column: IColumn) => {
        const sortInfo: ColumnSortInfo = {
            sortField: column.fieldName!,
            isSortedDescending: !column.isSortedDescending
        }
        setSortColumn(sortInfo);
    }, []);

    useEffect(() => {
        const columns = buildOrganizationColumns(onColumnClick, sortColumn);
        setOrganizationColumns(columns);
    }, [sortColumn, onColumnClick]);

    useEffect(() => {
        loadOrganizations(0, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortColumn, searchTerm]);

    const selection: Selection = new Selection({
        onSelectionChanged: () => onSelectedOrganizationChanged(selection.getSelection())
    });

    const onSelectedOrganizationChanged = (selectedObjects: IObjectWithKey[]) => {
        if (selectedObjects && selectedObjects.length) {
            setSelectedOrganization(selectedObjects[0] as OrganizationDto);
        } else {
            setSelectedOrganization(null);
        }
    }

    const items: ICommandBarItemProps[] = [
        {
            key: 'newOrganization',
            text: "Neuer Händler...",
            iconProps: { iconName: 'Add' },
            onClick: navigateToNewOrganization
        },
        {
            key: 'editOrganization',
            text: 'Händler bearbeiten...',
            iconProps: { iconName: 'Edit' },
            disabled: selectedOrganization == null,
            onClick: () => navigateToEditOrganization(selectedOrganization!.id)
        }
    ];

    const searchBar: ICommandBarItemProps[] = [
        {
            key: 'searchBar',
            onRender: () => (
                <SearchBox
                    style={{ width: '200px' }}
                    placeholder='Suche...'
                    value={searchTerm}
                    onSearch={searchValue => setSeachTerm(searchValue)}
                />
            )
        }
    ]

    return (
        <Stack>
            <CommandBar
                items={items}
                farItems={searchBar}
            />
            <DetailsList
                items={organizations}
                columns={organizationColumns}
                selectionMode={SelectionMode.single}
                onRenderMissingItem={onRenderMissingItem}
                getKey={(item?: OrganizationDto) => item ? item.id : 'null-item'}
                selection={selection}
            />
        </Stack>
    );
}

export default OrganizationsAdministration;
