import React from 'react';
import UserDto from 'app/user-management/user-dto';
import { Stack } from '@fluentui/react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from 'app/navigation/protected-route';
import { Role } from 'app/user-management/role';
import Routes from './routes';
import FinishReportPage from 'app/damage-reports/finish-report/finish-report-page';
import EditReportPage from 'app/damage-reports/edit-report/edit-report-page';
import ReportSubmittedSuccessfullyPage from 'app/damage-reports/edit-report/report-submitted-successfully-page';
import ReportFinishedSuccessfullyPage from 'app/damage-reports/finish-report/report-finished-successfully-page';
import ReportsPage from 'app/damage-reports/reports-page';
import EditOrganizationPage from 'app/user-management/administration/organizations/edit-organization-page';
import EditUserPage from 'app/user-management/administration/users/edit-user-page';
import UserManagementPage from 'app/user-management/administration/user-management-page';
import ChangePasswordPage from 'app/user-management/change-password/change-password-page';
import AutoLogin from 'app/user-management/login/auto-login';
import LoginPage from 'app/user-management/login/login-page';
import AuthResponsePage, { AuthResponseType } from 'app/user-management/shared/auth-response-page';
import SignUpPage from 'app/user-management/sign-up/sign-up-page';
import RequestPasswordResetPage from 'app/user-management/password-reset/request-password-reset-page';
import SentPasswordResetPage from 'app/user-management/password-reset/sent-password-reset-page';
import ResetPasswordPage from 'app/user-management/password-reset/reset-password-page';
import Imprint from 'app/app-shell/imprint';
import ThemedSelectExample from 'app/forms/themed-select/themed-select-example';
import ConfirmEmail from 'app/user-management/confirm-email/confirm-email';
import useUserContext from 'app/user-management/user-context';
import Contact from 'app/app-shell/contact';
import UnconfirmedAccountPage from 'app/user-management/login/unconfirmed-account-page';

export interface RoutedContentProperties {
    loginUser: (user: UserDto, jwtToken: string) => void;
}

export default function RoutedContent(props: RoutedContentProperties): JSX.Element {

    const user = useUserContext();

    return (
        <Stack style={{ flexGrow: 1 }}>
            <Switch>

                <ProtectedRoute path='/reports/finish/:reportId+' allowedRoles={[Role.OrganizationAdmin, Role.OrganizationMember]} fallbackRoute={Routes.userManagementUsers}>
                    <FinishReportPage />
                </ProtectedRoute>

                <ProtectedRoute path='/reports/edit/:reportId+' allowedRoles={[Role.OrganizationAdmin, Role.OrganizationMember]} fallbackRoute={Routes.userManagementUsers}>
                    <EditReportPage />
                </ProtectedRoute>

                <ProtectedRoute path='/reports/submitSuccessful' allowedRoles={[Role.OrganizationAdmin, Role.OrganizationMember]} fallbackRoute={Routes.userManagementUsers}>
                    <ReportSubmittedSuccessfullyPage />
                </ProtectedRoute>

                <ProtectedRoute path='/reports/finishedSuccessfully' allowedRoles={[Role.OrganizationAdmin, Role.OrganizationMember]} fallbackRoute={Routes.userManagementUsers}>
                    <ReportFinishedSuccessfullyPage />
                </ProtectedRoute>

                <ProtectedRoute path='/reports' allowedRoles={[Role.OrganizationAdmin, Role.OrganizationMember]} fallbackRoute={Routes.userManagementUsers}>
                    <ReportsPage />
                </ProtectedRoute>

                <ProtectedRoute path="/usermanagement/organizations/new" allowedRoles={[Role.AonAdmin]} fallbackRoute={Routes.reports}>
                    <EditOrganizationPage />
                </ProtectedRoute>

                <ProtectedRoute path="/usermanagement/organizations/edit/:editOrgId+" allowedRoles={[Role.AonAdmin]} fallbackRoute={Routes.reports}>
                    <EditOrganizationPage />
                </ProtectedRoute>

                <ProtectedRoute path="/usermanagement/users/new" allowedRoles={[Role.AonAdmin]} fallbackRoute={Routes.reports}>
                    <EditUserPage />
                </ProtectedRoute>

                <ProtectedRoute path="/usermanagement/users/edit/:editUserId+" allowedRoles={[Role.AonAdmin]} fallbackRoute={Routes.reports}>
                    <EditUserPage />
                </ProtectedRoute>

                <ProtectedRoute path="/usermanagement/:selectedPivotItemKey" allowedRoles={[Role.AonAdmin]} fallbackRoute={Routes.reports}>
                    <UserManagementPage />
                </ProtectedRoute>

                <ProtectedRoute path={Routes.changePassword} allowedRoles={[Role.AonAdmin, Role.OrganizationAdmin, Role.OrganizationMember]} fallbackRoute={Routes.login}>
                    <ChangePasswordPage />
                </ProtectedRoute>

                <Route path={Routes.autoLogin}>
                    <AutoLogin onLogin={props.loginUser} />
                </Route>
                <Route path={Routes.login}>
                    <LoginPage onLogin={props.loginUser} />
                </Route>
                <Route path={Routes.emailNotConfirmed}>
                    <AuthResponsePage type={AuthResponseType.EmailNotConfirmed} />
                </Route>

                <Route path={Routes.accountCreated}>
                    <AuthResponsePage type={AuthResponseType.RegisterConfirmEmail} />
                </Route>

                <Route path={Routes.inactiveAccount}>
                    <UnconfirmedAccountPage />
                </Route>

                <Route path={Routes.unconfirmedAccount}>
                    <UnconfirmedAccountPage />
                </Route>

                <Route path={Routes.signUp} component={SignUpPage} />
                <Route path={Routes.requestPasswordReset} component={RequestPasswordResetPage} />
                <Route path={Routes.sentPasswordReset} component={SentPasswordResetPage} />
                <Route path={Routes.resetPassword} component={ResetPasswordPage} />
                <Route path={Routes.imprint} component={Imprint} />
                <Route path={Routes.contact} component={Contact} />
                <Route path={Routes.confirmEmail} component={ConfirmEmail} />
                <Route path={Routes.themedSelectExample} component={ThemedSelectExample} />

                {!user && <Redirect to={{ pathname: Routes.autoLogin }} />}
                {user && user.role === Role.AonAdmin && <Redirect to={{ pathname: Routes.userManagementUsers }} />}
                {user && <Redirect to={{ pathname: Routes.reports }} />}
            </Switch>
        </Stack>
    );
}
