import { StylesConfig } from "react-select";
import useThemeContext from '../../theming/theme-context';

export default function useThemedSelectStyle(): StylesConfig {

    const theme = useThemeContext();

    return {
        control: (_provided, state) => ({
            color: theme.semanticColors.inputText,
            backgroundColor: state.isDisabled ? theme.semanticColors.disabledBackground : theme.semanticColors.inputBackground,
            minHeight: 'initial',
            height: '32px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderColor: state.isDisabled ? theme.semanticColors.disabledBackground : state.isFocused ? theme.palette.themePrimary : theme.semanticColors.inputBorder,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: '2px',
            WebkitBoxAlign: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            cursor: 'text',
            outline: '0 !important',
            boxShadow: state.isFocused ? `0 0 0 1px ${theme.palette.themePrimary}` : 'none'
        }),
        valueContainer: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,

        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '2px',
            borderWidth: 0,
            boxShadow: theme.effects.elevation16,
            marginTop: 1,
            background: theme.semanticColors.menuBackground,
            color: theme.semanticColors.menuItemText
        }),
        menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0
        }),
        option: (provided, state) => ({
            ...provided,
            minheight: '36px',
            color: state.isFocused ? theme.semanticColors.menuItemTextHovered : theme.semanticColors.menuItemText,
            backgroundColor: state.isFocused ? theme.semanticColors.menuItemBackgroundHovered : theme.semanticColors.menuBackground
        }),
        indicatorSeparator: () => ({
            width: '1px',
            backgroundColor: theme.semanticColors.menuItemBackgroundHovered,
            alignSelf: 'stretch',
            marginTop: theme.spacing.s1,
            marginBottom: theme.spacing.s1
        }),
        dropdownIndicator: (_provided, state) => ({
            padding: '4px',
            display: 'flex',
            color: state.isDisabled ? theme.semanticColors.disabledBodySubtext : theme.semanticColors.bodySubtext,
            backgroundColor: state.selectProps.menuIsOpen ? theme.semanticColors.menuItemBackgroundHovered : 'transparent'
        }),
        clearIndicator: () => ({
            padding: '4px',
            display: 'flex',
            cursor: 'default',
            color: theme.semanticColors.bodySubtext
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme.semanticColors.inputText
        }),
        input: (provided) => ({
            ...provided,
            color: theme.semanticColors.inputText
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? theme.semanticColors.disabledBodySubtext : theme.semanticColors.bodySubtext
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            color: theme.semanticColors.bodySubtext
        })
    };
}