import { IColumn } from "office-ui-fabric-react";
import { ColumnSortInfo } from "../../../lists/column-sort-info";

export function buildOrganizationColumns(onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void, sortColumn: ColumnSortInfo | null): IColumn[] {
  return [
    { 
      key: 'orgNumber', 
      name: 'Händlerkennung', 
      fieldName: 'organizationNumber',
      data: 'number',
      minWidth: 180, 
      maxWidth: 180,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'organizationNumber' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'organizationNumber' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'name', 
      name: 'Name', 
      fieldName: 'name', 
      data: 'string',
      minWidth: 240, 
      maxWidth: 240,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'name' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'name' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'street', 
      name: 'Straße', 
      fieldName: 'street', 
      data: 'string',
      minWidth: 240, 
      maxWidth: 240,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'street' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'street' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'postalCode', 
      name: 'Postleitzahl', 
      fieldName: 'zipCode',
      data: 'number',
      minWidth: 140, 
      maxWidth: 140,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'zipCode' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'zipCode' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'location', 
      name: 'Ort', 
      fieldName: 'location',
      data: 'string',
      minWidth: 240, 
      maxWidth: 240,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'location' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'location' ? sortColumn.isSortedDescending : true
    },
    {
      key: 'retailerNumber', 
      name: 'Händlernummer', 
      fieldName: 'retailerNumber',
      data: 'string',
      minWidth: 240, 
      maxWidth: 240,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'retailerNumber' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'retailerNumber' ? sortColumn.isSortedDescending : true
    },
    { 
      key: 'iban', 
      name: 'IBAN', 
      fieldName: 'internationalBankAccountNumber', 
      data: 'string',
      minWidth: 240, 
      maxWidth: 240,
      isResizable: true,
      onColumnClick: onColumnClick,
      isSorted: sortColumn && sortColumn.sortField === 'internationalBankAccountNumber' ? true : false,
      isSortedDescending: sortColumn && sortColumn.sortField === 'internationalBankAccountNumber' ? sortColumn.isSortedDescending : true
    }    
  ];
}