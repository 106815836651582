import React from 'react';
import Select, { Props, OptionTypeBase } from 'react-select';
import { Stack, Label } from '@fluentui/react';
import useThemedSelectStyle from './use-themed-select-style';
import ThemedSelectError from './themed-select-error';

export interface ThemedSelectProperties<OptionType extends OptionTypeBase = { label: string; value: any }> extends Props<OptionType> {
    label?: string;
    errorMessage?: string | JSX.Element;
}

export default function ThemedSelect(props: ThemedSelectProperties): JSX.Element {

    const themedSelectStyles = useThemedSelectStyle();

    return (
        <Stack>
            {
                props.label &&
                <Label
                    htmlFor={props.id}
                    disabled={props.isDisabled}
                >
                    {props.label}
                </Label>
            }
            <Select
                {...props}
                styles={themedSelectStyles}
            />
            {
                props.errorMessage &&
                <ThemedSelectError errorMessage={props.errorMessage} />
            }
        </Stack>
    );
}
