import React from "react";
import { Stack } from "@fluentui/react";
import useThemeContext from "../theming/theme-context";
import NavigationLink from "app/navigation/navigation-link";
import ExternalLink from "app/navigation/external-link";
import Routes from "app/navigation/routes";

export default function Footer(): JSX.Element {
	const theme = useThemeContext();

	return (
		<Stack
			style={{
				flexBasis: 40,
				width: "100%",
				background: theme.palette.neutralLighter,
				boxShadow: theme.effects.elevation8,
				paddingLeft: theme.spacing.l1,
				paddingRight: theme.spacing.l1,
			}}
			horizontalAlign="center"
			verticalAlign="center"
		>
			<Stack
				horizontal
				tokens={{ childrenGap: theme.spacing.l1 }}
				verticalAlign="baseline"
			>
				<p>AON Schadensanzeige für Stellantis Neuwagen 1.4.0</p>

				<p>|</p>

				<NavigationLink to={Routes.contact} text="Kontakt" />

				<p>|</p>

				<NavigationLink to={Routes.imprint} text="Impressum" />

				<p>|</p>

				<ExternalLink
					href="https://www.synnotech.de"
					text="Created with ♥ by Synnotech"
				/>
			</Stack>
		</Stack>
	);
}
