export default class AreaCodes {
    static readonly antenna = '01';
    static readonly battery = '02';
    static readonly frontBumper = '03';
    static readonly rearBumper = '04';
    static readonly leftFrontDoor = '10';
    static readonly leftRearDoor = '11';
    static readonly rightFrontDoor = '12';
    static readonly rightRearDoor = '13';
    static readonly leftFrontFender = '14';
    static readonly leftRearFender = '15';
    static readonly rightFrontFender = '16';
    static readonly rightRearFender = '17';
    static readonly windshield = '20';
    static readonly rearWindow = '21';
    static readonly radiatorGrill = '22';
    static readonly headlights = '24';
    static readonly fogLights = '25';
    static readonly hood = '27';
    static readonly keys = '28';
    static readonly leftSideMirror = '30';
    static readonly rightSideMirror = '31';
    static readonly radio = '33';
    static readonly leftSideSkirt = '35';
    static readonly rightSideSkirt = '36';
    static readonly roof = '37';
    static readonly leftShoulderLine = '38';
    static readonly rightShoulderLine = '39';
    static readonly spareTire = '40';
    static readonly tailLights = '45';
    static readonly tires = '47';
    static readonly trunk = '52';
    static readonly sunroof = '53';
    static readonly rims = '57';
    static readonly frontWipers = '59a';
    static readonly rearWipers = '59b';
    static readonly cigaretteLighter = '67';
    static readonly rightBPillar = '69';
    static readonly leftBPillar = '70';
    static readonly rightAPillar = '71';
    static readonly leftAPillar = '72';
    static readonly rightCPillar = '73';
    static readonly leftCPillar = '74';
    static readonly fuelTankCap = '81';
    static readonly tools = '84';
    static readonly emergencyCallSystem = '85';
    static readonly exhaust = '91';
    static readonly frontLicensePlateHolder = '92a';
    static readonly rearLicensePlateHolder = '92b';
    static readonly driverSeat = '94';
    static readonly passengerSeat = '95';
    static readonly backSeats = '96';

    static readonly options = [
        AreaCodes.createOption(AreaCodes.antenna, 'Antenne'),
        AreaCodes.createOption(AreaCodes.battery, 'Batterie'),
        AreaCodes.createOption(AreaCodes.frontBumper, 'Vordere Stoßstange'),
        AreaCodes.createOption(AreaCodes.rearBumper, 'Hintere Stoßstange'),
        AreaCodes.createOption(AreaCodes.leftFrontDoor, 'Linke Vordertür'),
        AreaCodes.createOption(AreaCodes.leftRearDoor, 'Linke Hintertür'),
        AreaCodes.createOption(AreaCodes.rightFrontDoor, 'Rechte Vordertür'),
        AreaCodes.createOption(AreaCodes.rightRearDoor, 'Rechte Hintertür'),
        AreaCodes.createOption(AreaCodes.leftFrontFender, 'Linker vorderer Kotflügel'),
        AreaCodes.createOption(AreaCodes.rightFrontFender, 'Rechter vorderer Kotflügel'),
        AreaCodes.createOption(AreaCodes.rightRearFender, 'Rechter hinterer Kotflügel'),
        AreaCodes.createOption(AreaCodes.leftRearFender, 'Linker hinterer Kotflügel'),
        AreaCodes.createOption(AreaCodes.windshield, 'Windschutzscheibe'),
        AreaCodes.createOption(AreaCodes.rearWindow, 'Heckscheibe'),
        AreaCodes.createOption(AreaCodes.radiatorGrill, 'Kühlergrill'),
        AreaCodes.createOption(AreaCodes.headlights, 'Scheinwerfer'),
        AreaCodes.createOption(AreaCodes.fogLights, 'Nebelscheinwerfer'),
        AreaCodes.createOption(AreaCodes.hood, 'Motorhaube'),
        AreaCodes.createOption(AreaCodes.keys, 'Schlüssel'),
        AreaCodes.createOption(AreaCodes.leftSideMirror, 'Linker Seitenspiegel'),
        AreaCodes.createOption(AreaCodes.rightSideMirror, 'Rechter Seitenspiegel'),
        AreaCodes.createOption(AreaCodes.radio, 'Radio'),
        AreaCodes.createOption(AreaCodes.leftSideSkirt, 'Linker Seitenschweller'),
        AreaCodes.createOption(AreaCodes.rightSideSkirt, 'Rechter Seitenschweller'),
        AreaCodes.createOption(AreaCodes.roof, 'Dach'),
        AreaCodes.createOption(AreaCodes.leftShoulderLine, 'Linke Schulterlinie'),
        AreaCodes.createOption(AreaCodes.rightShoulderLine, 'Lechte Schulterlinie'),
        AreaCodes.createOption(AreaCodes.spareTire, 'Ersatzrad'),
        AreaCodes.createOption(AreaCodes.tailLights, 'Rückleuchten'),
        AreaCodes.createOption(AreaCodes.tires, 'Reifen'),
        AreaCodes.createOption(AreaCodes.trunk, 'Kofferraum'),
        AreaCodes.createOption(AreaCodes.sunroof, 'Schiebedach'),
        AreaCodes.createOption(AreaCodes.rims, 'Felgen'),
        AreaCodes.createOption(AreaCodes.frontWipers, 'Frontwischer'),
        AreaCodes.createOption(AreaCodes.rearWipers, 'Heckwischer'),
        AreaCodes.createOption(AreaCodes.cigaretteLighter, 'Zigarettenanzünder'),
        AreaCodes.createOption(AreaCodes.leftAPillar, 'Linke A Säule'),
        AreaCodes.createOption(AreaCodes.rightAPillar, 'Rechte A Säule'),
        AreaCodes.createOption(AreaCodes.leftBPillar, 'Linke B Säule'),
        AreaCodes.createOption(AreaCodes.rightBPillar, 'Rechte B Säule'),
        AreaCodes.createOption(AreaCodes.leftCPillar, 'Linke C Säule'),
        AreaCodes.createOption(AreaCodes.rightCPillar, 'Rechte C Säule'),
        AreaCodes.createOption(AreaCodes.fuelTankCap, 'Tankdeckel'),
        AreaCodes.createOption(AreaCodes.tools, 'Werkzeug'),
        AreaCodes.createOption(AreaCodes.emergencyCallSystem, 'Notrufsystem'),
        AreaCodes.createOption(AreaCodes.exhaust, 'Auspuff'),
        AreaCodes.createOption(AreaCodes.frontLicensePlateHolder, 'Vorderes Nummernschhild'),
        AreaCodes.createOption(AreaCodes.rearLicensePlateHolder, 'Hinteres Nummernschild'),
        AreaCodes.createOption(AreaCodes.driverSeat, 'Fahrersitz'),
        AreaCodes.createOption(AreaCodes.passengerSeat, 'Beifahrersitz'),
        AreaCodes.createOption(AreaCodes.backSeats, 'Rücksitze')
    ];

    private static createOption(areaCode: string, label: string): { label: string, value: string } {
        return { value: areaCode, label: areaCode + " - " + label };
    }
}
