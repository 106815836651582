import React, { CSSProperties } from 'react';
import CenteredView from 'app/view-layout/centered-view';
import { Link } from '@fluentui/react';
import useThemeContext from 'app/theming/theme-context';

export default function UnconfirmedAccountPage(): JSX.Element {

    const theme = useThemeContext();

    const paragraphStyle: CSSProperties = {
        marginBottom: theme.spacing.s1
    };

    return (
        <CenteredView>
            <h2 style={paragraphStyle}>Aktivierung ausstehend</h2>
            <p style={paragraphStyle}>Ihr Antrag auf Nutzung des Portals wurde noch nicht bestätigt, bitte versuchen Sie die Anmeldung zu einem späteren Zeitpunkt erneut, vielen Dank.</p>
            <p style={paragraphStyle}>Bei Fragen wenden Sie sich bitte an:</p>
            <p>
                Bernhard Schöpf<br />
                One Underwriting Agency GmbH<br />
                +49 208 7006 2612<br />
                <Link href="mailto:bernhard.schoepf@oneunderwriting.de">bernhard.schoepf@oneunderwriting.de</Link>
            </p>
        </CenteredView>
    );
}