import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Role } from '../user-management/role';
import Routes from 'app/navigation/routes';
import useUserContext from 'app/user-management/user-context';

export interface ProtectedRouteProperties {
    path: string;
    children?: React.ReactNode;
    allowedRoles: Role[];
    fallbackRoute: string;
}

export default function ProtectedRoute(props: ProtectedRouteProperties): JSX.Element {

    const user = useUserContext();

    return (
        <Route
            exact
            path={props.path}
            render={({ location }) => {
                if (!user) {
                    return <Redirect to={{ pathname: Routes.autoLogin, state: { from: location } }} />
                }
                if (!props.allowedRoles.some(role => role === user.role)) {
                    return <Redirect to={{ pathname: props.fallbackRoute, state: { from: location } }} />
                }
                return (props.children);
            }}
        />
    );
}
