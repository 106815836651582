import React, { CSSProperties, useContext } from 'react';
import { UnassignedUserDto } from './infrastructure/unassigned-user-dto';
import { Link } from '@fluentui/react';
import { ThemeContext } from '../../../theming/theme-context';

interface Types {
  users: UnassignedUserDto[],
  onEditUser: (userId: string) => void
}

const UnassignedUsers = (props: Types) => {

  const theme = useContext(ThemeContext);

  const listStyle: CSSProperties = {
    paddingTop: theme.spacing.s2,
    paddingLeft: theme.spacing.m
  }

  return (
    <>
      <h3>Ausstehende Benutzer</h3>    
      <div style={listStyle}>
        {
          props.users.map(user => (
            <div key={user.id}>
              <Link onClick={() => props.onEditUser(user.id)}>
                {user.email}
              </Link>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default UnassignedUsers;
