import React, { useCallback, useEffect, useState } from "react";
import {
    Stack,
    IColumn,
    Selection,
    IObjectWithKey,
    DetailsList,
    SelectionMode,
    CommandBar,
    ICommandBarItemProps,
    SearchBox,
} from "@fluentui/react";
import { ColumnSortInfo } from "../../lists/column-sort-info";
import { ReportDto } from "../report-dto";
import axios from "axios";
import { buildReportColumns } from "./build-report-columns";
import { ReportState } from "../report-state";
import { ReportEntry } from "./report-entry";
import MasterView from "../../view-layout/master-view";
import useDebouncedState from "../../common/use-debounced-state";
const pageSize: number = 40;

interface ReportListProperties {
    onCreateReport: () => Promise<void>;
    onEditReport: (report: ReportDto) => void;
    onDeleteReport: (reportId: string) => Promise<void>;
}

export default function ReportsList(props: ReportListProperties): JSX.Element {
    const [isProcessing, setIsProcessing] = useState(false);

    const [reports, setReports] = useState<(ReportEntry | null)[]>([]);
    const [debouncedSearchTerm, searchTerm, setSearchTerm] =
        useDebouncedState<string>("");
    const [reportColumns, setReportColumns] = useState<IColumn[]>([]);
    const [sortColumn, setSortColumn] = useState<ColumnSortInfo | null>(null);
    const [selectedReport, setSelectedReport] = useState<ReportDto | null>(
        null
    );

    const onCreateReport = async () => {
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);
        try {
            await props.onCreateReport();
        } catch {
            // TODO: we should actually show an error message here
            setIsProcessing(false);
        }
    };

    const onDeleteReport = async () => {
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);
        await props.onDeleteReport(selectedReport!.id);
        const newReports = reports.filter(
            (report) => report == null || report.id !== selectedReport!.id
        );

        setReports(newReports);
        setIsProcessing(false);
    };

    const loadReports = useCallback(
        async (index: number, appendToExisting: boolean = true) => {
            if (!appendToExisting) {
                setReports([]);
            }

            const response = await axios.get<ReportDto[]>("/api/reports", {
                params: {
                    skip: index,
                    take: pageSize + 1,
                    sortField: sortColumn ? sortColumn.sortField : null,
                    isAscendingSortOrder: sortColumn
                        ? !sortColumn.isSortedDescending
                        : null,
                    searchTerm: debouncedSearchTerm,
                },
            });

            const loadedReports: (ReportDto | null)[] =
                response.data.length > pageSize
                    ? [...response.data.slice(0, pageSize), null]
                    : response.data;

            const newReports = appendToExisting
                ? [...reports.slice(0, reports.length - 1), ...loadedReports]
                : loadedReports;

            setReports(
                newReports.map((dto) => ReportEntry.FromDto(dto as ReportDto))
            );
        },
        [reports, sortColumn, debouncedSearchTerm]
    );

    const onRenderMissingItem = (index?: number) => {
        if (index != null) {
            loadReports(index);
        }
        return <></>;
    };

    const onColumnClick = useCallback((_: any, column: IColumn) => {
        const sortInfo: ColumnSortInfo = {
            sortField: column.key!,
            isSortedDescending: !column.isSortedDescending,
        };
        setSortColumn(sortInfo);
    }, []);

    useEffect(() => {
        const columns = buildReportColumns(onColumnClick, sortColumn);
        setReportColumns(columns);
    }, [sortColumn, onColumnClick]);

    useEffect(() => {
        loadReports(0, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortColumn, debouncedSearchTerm]);

    const selection: Selection = new Selection({
        onSelectionChanged: () =>
            onSelectedReportChanged(selection.getSelection()),
    });

    const onSelectedReportChanged = (selectedObjects: IObjectWithKey[]) => {
        if (selectedObjects && selectedObjects.length) {
            setSelectedReport(selectedObjects[0] as ReportDto);
        } else {
            setSelectedReport(null);
        }
    };

    const items: ICommandBarItemProps[] = [
        {
            key: "newReport",
            text: "Neue Schadensanzeige...",
            iconProps: { iconName: "Add" },
            disabled: isProcessing,
            onClick: (): void => {
                onCreateReport();
            },
        },
        {
            key: "editReport",
            text: "Schadensanzeige bearbeiten...",
            iconProps: { iconName: "Edit" },
            disabled:
                !selectedReport ||
                isProcessing ||
                selectedReport.state === ReportState.Finished,
            onClick: () => props.onEditReport(selectedReport!),
        },
        {
            key: "deleteReport",
            text: "Schadensanzeige löschen...",
            iconProps: { iconName: "Delete" },
            disabled:
                !selectedReport ||
                isProcessing ||
                selectedReport.state !== ReportState.Created,
            onClick: (): void => {
                onDeleteReport();
            },
        },
    ];

    const searchBar: ICommandBarItemProps[] = [
        {
            key: "searchBar",
            onRender: () => (
                <SearchBox
                    style={{ width: "200px" }}
                    placeholder="Suche..."
                    value={searchTerm}
                    onChange={(_, value) => setSearchTerm(value ?? "")}
                />
            ),
        },
    ];

    return (
        <MasterView>
            <Stack>
                <CommandBar items={items} farItems={searchBar} />
                <DetailsList
                    items={reports}
                    columns={reportColumns}
                    selectionMode={SelectionMode.single}
                    onRenderMissingItem={onRenderMissingItem}
                    getKey={(item?: ReportDto) =>
                        item ? item.id : "null-item"
                    }
                    selection={selection}
                />
            </Stack>
        </MasterView>
    );
}
