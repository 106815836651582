import React from 'react';
import ReportsList from './report-list/reports-list';
import { useHistory } from 'react-router-dom';
import Routes from 'app/navigation/routes';
import axios from 'axios';
import { ReportDto } from './report-dto';
import { ReportState } from './report-state';

export default function ReportsPage(): JSX.Element {

    const history = useHistory();

    const editReport = (report: ReportDto) => {
        if (!report) {
            return;
        }

        if (report.state === ReportState.Created) {
            history.push(Routes.getEditReportUrl(report.id));
        } else if (report.state === ReportState.Submitted) {
            history.push(Routes.getFinishReportUrl(report.id));
        }
    }

    const createReport = async () => {
        const response = await axios.post<string>('/api/reports/create');
        history.push(Routes.getEditReportUrl(response.data));
    }

    const deleteReport = async (reportId: string) => {
        await axios.delete('/api/reports/delete/' + encodeURIComponent(reportId));
    }

    return (
        <ReportsList
            onCreateReport={createReport}
            onEditReport={editReport}
            onDeleteReport={deleteReport}
        />
    );
}
