import React, { useContext, useState } from 'react';
import { Stack, DetailsList, PrimaryButton, Selection, IObjectWithKey, SelectionMode } from '@fluentui/react';
import { ThemeContext } from '../../theming/theme-context';
import { DamageDetail } from './damage-detail';
import { buildDamageDetailsColumns } from './build-damage-details-columns';

export interface DamageDetailsSectionProperties {
    onCreateDetailClick: () => void;
    onDeleteDetailClick: (detail: DamageDetail) => void;
    details: DamageDetail[];
    errors: any;
}

export default function DamageDetailsSection(props: DamageDetailsSectionProperties): JSX.Element {

    const theme = useContext(ThemeContext);
    const [selectedDetail, setSelectedDetail] = useState<DamageDetail | null>();

    const selection: Selection = new Selection({
        onSelectionChanged: () => onSelectedUserChanged(selection.getSelection())
    });

    const onSelectedUserChanged = (selectedObjects: IObjectWithKey[]) => {
        if (selectedObjects && selectedObjects.length) {
            setSelectedDetail(selectedObjects[0] as DamageDetail);
        } else {
            setSelectedDetail(null);
        }
    }

    const columns = buildDamageDetailsColumns(() => { }, null);
    const details = props.details == null ? [] : props.details;

    return (
        <Stack style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Stack style={{ paddingTop: theme.spacing.m }}>
                <Stack horizontal tokens={{ childrenGap: 8, padding: '0 0 1em 0' }}>
                    <PrimaryButton
                        text='Schaden erfassen'
                        onClick={props.onCreateDetailClick}
                    />
                    <PrimaryButton
                        text='Schaden löschen'
                        disabled={selectedDetail == null}
                        onClick={() => props.onDeleteDetailClick(selectedDetail!)}
                    />
                </Stack>

                <div style={{ width: '100%' }}>
                    <DetailsList
                        styles={{ root: { height: '100%', maxWidth: '100%', minWidth: '1px' } }}
                        items={details}
                        columns={columns}
                        selectionMode={SelectionMode.single}
                        selection={selection}
                    />
                </div>

                {
                    props.errors.damageDetails && props.errors.damageDetails.type === 'validate' &&
                    <p className='ms-TextField-errorMessage'><span className='error-message'>Bitte mindestens einen Schaden angeben</span></p>
                }

                <img
                    style={{ width: '100%', height: 'auto', marginTop: theme.spacing.l2 }}
                    src={process.env.PUBLIC_URL + '/car.png'}
                    alt='car damage sections'
                />
            </Stack>
        </Stack>
    )
}
